@charset "UTF-8";

.ocw-header {
  width: 100%;
  background-color: #FFF;
  @include sp {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    &, .ocw-search, .ocw-course-search, .ocw-gnav {
      transition: all 0.75s ease-in-out;
    }
    &.hidden {
      transform: translateY(-100%);
      .ocw-search, .ocw-course-search, .ocw-gnav {
        opacity: 0;
      }
    }
  }
	.ocw-upper {
    @include row;
    padding-top: 20px;
    padding-bottom: 15px;
    @include sp {
      padding-top: 0;
      padding-bottom: 0;
    }
    .ocw-inner {
      @include row;
      @include sp {
        padding-right: 0;
        height: 56px;
      }
    }
    .ocw-site-title {
      float: left;
      @include sp {
        padding-top: 14px;
      }
      a {
        display: block;
        img {
          height: 60px;
          @include sp {
            height: 32px;
          }
        }
      }
    }
    .ocw-misc {
      @include tab_pc {
        float: right;
        display: table;
      }
      .ocw-search {
        @include tab_pc {
          display: table-cell;
          vertical-align: middle;
        }
        @include sp {
          position: fixed;
          z-index: 10000;
          width: 100%;
          left: 0;
          top: 56px;
          background-color: #EEE;
          display: none;
          padding: 15px;
          background-color: #EEE;
        }
        input {
          float: left;
          height: 35px;
          padding: 0 0.5em;
          border: 1px solid #768FC5;
          background-color: #FFF;
          @include tab_pc {
            width: 240px;
            margin-right: 5px;
          }
          @include sp {
            width: calc(100% - 10px - 38px);
            margin-right: 10px;
          }
        }
        button {
          float: left;
          text-align: center;
          width: 38px;
          height: 35px;
          line-height: 35px;
          background-color: $blue;
          color: #FFF;
          border-radius: 5px;
          &:after {
            @include fa;
            content: '\f002';
            font-weight: bold;
            padding-top: 1px;
            margin-right: -1px;
          }
        }
      }
      .ocw-logo {
        @include sp_hide;
        display: table-cell;
        vertical-align: middle;
        padding-left: 25px;
        a {
          display: block;
          img {
            height: 52px;
          }
        }
      }
    }
    .ocw-misc-sp {
      @include sp_show;
      float: right;
      display: table;
      .ocw-search-sw, .ocw-gnav-sw {
        display: table-cell;
        vertical-align: top;
        text-align: center;
        a {
          display: block;
          text-align: center;
          font-size: 10px;
          line-height: 1;
          color: $blue;
          width: 56px;
          height: 56px;
          padding: 13px 0;
          &.active {
            background-color: #EEE;
          }
          &:before {
            @include fa;
            display: block;
            font-weight: bold;
            font-size: 17px;
            margin-bottom: 3px;
          }
        }
      }
      .ocw-search-sw {
        a {
          &:before {
            content: '\f002';
          }
        }
      }
      .ocw-gnav-sw {
        a {
          &:before {
            content: '\f0c9';
          }
        }
      }
    }
	}
	.ocw-lower {
    @include row;
    background-color: #FFF;
    padding-bottom: 15px;
    @include sp {
      padding-bottom: 0;
    }
    .ocw-gnav {
      float: left;
      @include sp {
        display: none;
        position: fixed;
        z-index: 10000;
        right: 0;
        top: 56px;
        background-color: #EEE;
        width: 185px;
      }
      & > ul {
        @include tab_pc {
          display: table;
        }
        & > li {
          @include tab_pc {
            display: table-cell;
            @include notlast {
              padding-right: 1.25em;
            }
          }
          @include sp {
            padding: 5px 15px;
            @include notlast {
              border-bottom: 1px solid #CCC;
            }
          }
          &.ocw-gnav01 a:before { content: '\f05a'; }
          &.ocw-gnav02 a:before { content: '\f5da'; }
          &.ocw-gnav03 a:before { content: '\f0ac'; }
          &.ocw-gnav04 a:before { content: '\f02d'; }
          &.ocw-gnav05 a:before { content: '\f002'; }
          a {
            color: $blue;
            font-size: 18px;
            font-weight: bold;
            line-height: 23px;
            @include sp {
              font-size: 16px;
            }
            &:hover {
              color: $lightblue;
            }
            &:before {
              @include fa;
            }
          }
          &.sp_show {
            @include tab_pc {
              display: none;
            }
            @include sp {
              @include row;
              padding: 8px 15px;
            }
            .ocw-logo {
              float: left;
              height: 23px;
            }
            .ocw-lang {
              float: right;
            }
          }
        }
      }
    }
    .ocw-lang {
      float: right;
      ul {
        display: table;
        li {
          display: table-cell;
          &.ocw-active {
            a {
              background-color: $blue;
              color: #FFF;
            }
          }
          a {
            display: block;
            width: 64px;
            text-align: center;
            padding-top: 1px;
            background-color: #BBB;
            color: #888;
            font-size: 14px;
            font-weight: bold;
            line-height: 23px;
            @include sp {
              font-size: 13px;
              width: 60px;
            }
          }
        }
      }
    }
	}
	.ocw-course-search {
    background-color: $blue;
    @include tab_pc {
      padding: 10px 0;
    }
    @include sp {
      position: fixed;
      z-index: 10000;
      display: none;
      padding: 15px 0;
      width: 100%;
      left: 0;
      top: 121px;
    }
    dl {
      @include tab_pc {
        display: table;
      }
      @include sp {
        @include row;
      }
      dt {
        @include tab_pc {
          display: table-cell;
          vertical-align: middle;
          padding-right: 17px;
        }
        @include sp {
          margin-bottom: 8px;
        }
        span {
          position: relative;
          display: block;
          text-align: center;
          padding-left: 3px;
          font-size: 13px;
          width: 82px;
          line-height: 26px;
          background-color: #27488F;
          color: #FFF;
          &:after {
            position: absolute;
            display: block;
            content: '';
            border-width: 13px 7px;
            border-style: solid;
            border-color: transparent;
            border-left-color: #27488F;
            top: 0;
            left: 100%;
          }
        }
      }
      dd {
        @include tab_pc {
          display: table-cell;
          vertical-align: middle;
          @include notlast {
            padding-right: 5px;
          }
        }
        @include sp {
          float: left;
          &:nth-of-type(1) {
            width: calc(100% - 8px - 45px);
            margin-bottom: 8px;
          }
          &:nth-of-type(2) {
            margin-right: 8px;
          }
          &:nth-of-type(2), &:nth-of-type(3) {
            width: calc((100% - 8px - 8px - 45px) / 2);
          }
          &:nth-of-type(4) {
            padding-left: 8px;
          }
        }
        .ocw-select-wrapper {
          position: relative;
          @include sp {
            width: 100%;
          }
          &:after {
            pointer-events: none;
            position: absolute;
            display: block;
            content: '';
            right: 10px;
            width: 10px;
            height: 10px;
            top: 50%;
            transform: translateY(-75%) rotate(45deg);
            border-width: 0 3px 3px 0;
            border-style: solid;
            border-color: $blue;
          }
        }
        select, option {
          background-color: #FFF;
          height: 30px;
        }
        select {
          text-indent: 2px;
          @include tab_pc {
            min-width: 11em;
          }
          @include sp {
            width: 100%;
          }
        }
        option {
          min-width: 10em;
          padding-right: 1em;
          @include sp {
            width: 100%;
          }
        }
        input[type="reset"] {
          border: 1px solid #7798CF;
          background-color: transparent;
          border-radius: 5px;
          text-align: center;
          color: #7798CF;
          width: 45px;
          line-height: 30px;
          font-size: 11px;
        }
      }
    }
	}
}
