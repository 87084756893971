@charset "UTF-8";

.ocw-css {
  .ocw-contents-wrapper {
    background-image: none;
  }
  section {
    @include notlast {
      margin-bottom: 40px;
    }
  }
  .ocw-box {
    @include notfirstchild {
      margin-top: 20px;
    }
    @include notlastchild {
      margin-bottom: 20px;
    }
  }
}

.ocw-movie {
  .ocw-contents-wrapper {
    background-image: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  .ocw-movie-player-wrapper, .ocw-movie-info-wrapper,
  .ocw-movie-r1-wrapper, .ocw-movie-r2-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
    @include sp {
      padding-top: 0;
      padding-bottom: 15px;
    }
  }
  .ocw-movie-player-wrapper {
    @include sp {
      padding-top: 15px;
    }
    h2 {
      margin-bottom: 15px;
      @include sp {
        margin-bottom: 10px;
      }
      &:before {
        @include fa;
        background-color: transparent;
        vertical-align: baseline;
        @include sp {
        }
      }
    }
    .ocw-inner {
      width: 100%;
      @include tab_pc {
        display: flex;
        flex-wrap: nowrap;
      }
    }
    .ocw-movie-player {
      @include tab_pc {
        align-self: stretch;
        width: percentage(770/1170);
      }
      @include sp {
        width: 100%;
        margin-bottom: 15px;
      }
      h2 {
        &:before {
          content: '\f008';
        }
      }
      .ocw-movie-player-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 56.25%;
        @include sp {
          width: calc(100% + 30px);
          padding-top: calc((100% + 30px) * 0.5625);
          margin-left: -15px;
        }
        iframe {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
    }
    .ocw-movie-player-list {
      @include tab_pc {
        align-self: stretch;
        width: percentage(400/1170);
        padding-left: 30px;
      }
      @include sp {
        width: 100%;
      }
      h2 {
        &:before {
          content: '\f46d';
        }
      }
      li {
        position: relative;
        display: flex;
        width: 100%;
        padding: 9px 0;
        background-color: #DDD;
        &.ocw-active, &:hover {
          background-color: #EEE;
          .ocw-image {
            background-image: url('../images/player_list_arrow.png');
            @include tab_pc {
              background-position: 10px 50%;
              background-size: 12px auto;
            }
            @include sp {
              background-position: 8px 50%;
              background-size: 10px auto;
            }
          }
        }
        a {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: transparent;
        }
        .ocw-image {
          align-self: center;
          width: 50%;
          padding-left: 30px;
          @include sp {
            padding-left: 25px;
          }
        }
        .ocw-text {
          align-self: flex-start;
          width: 50%;
          left: 12px;
          font-size: 13px;
          padding-left: 12px;
          @include sp {
            font-size: 12px;
          }
          strong {
            font-size: 15px;
            @include sp {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .ocw-movie-info-wrapper {
    background-image: url('../images/bg_pattern_stripe.png');
    background-repeat: repeat;
    .ocw-inner {
      width: 100%;
      @include tab_pc {
        display: flex;
        flex-wrap: nowrap;
      }
      .ocw-movie-info1 {
        @include sp {
          margin-bottom: 15px;
        }
      }
      .ocw-movie-info1, .ocw-movie-info2 {
        @include tab_pc {
          width: calc((100% - 30px) / 2);
        }
        @include sp {
          width: 100%;
        }
      }
      .ocw-movie-info1 {
        margin-right: 30px;
        font-size: 14px;
        .ocw-box {
          padding: 10px 15px 30px;
          @include sp {
            padding-bottom: 20px;
          }
        }
        .ocw-movie-info1-data {
          padding: 10px 15px;
          background-color: #EEE;
          ul {
            @include row;
          }
          li {
            float: left;
            @include notlast {
              margin-right: 1.5em;
            }
          }
        }
        .ocw-cc {
          width: 88px;
          margin-top: 20px;
        }
      }
      .ocw-movie-info2 {
        .ocw-movie-info-teachers {
          font-size: 14px;
          @include tab_pc {
            padding: 20px 25px;
          }
          @include sp {
            padding: 15px 15px;
          }
          .ocw-items {
            @include tab_pc {
              @include grids(2, 30px, 30px);
            }
            @include sp {
            }
            li {
              display: flex;
              flex-wrap: nowrap;
              width: 100%;
              @include sp {
                @include notlast {
                  margin-bottom: 15px;
                }
              }
              .ocw-image {
                @include tab_pc {
                  width: 33%;
                }
                @include sp {
                  width: 80px;
                }
                img {
                  width: 100%;
                  height: auto;
                  border-radius: 50%;
                }
              }
              .ocw-text {
                width: 67%;
                padding-left: 15px;
                line-height: 1.5;
                .ocw-name {
                  color: $blue;
                  font-weight: bold;
                  margin-bottom: 0;
                }
                .ocw-ruby {
                  font-size: 12px;
                  margin-bottom: 2px;
                }
              }
            }
          }
        }
        .ocw-movie-info-link {
          padding: 10px 15px;
          font-size: 14px;
          &-pdf, &-img, &-ext {
            &:before {
              @include fa;
              font-weight: bold;
            }
          }
          &-pdf:before {
            content: '\f1c1';
          }
          &-img:before {
            content: '\f03e';
          }
          &-ext:before {
            content: '\f0c1';
          }
          ul {
            @include row;
          }
          li {
            @include tab_pc {
              float: left;
            }
            @include notlast {
              @include tab_pc {
                margin-right: 1em;
              }
              @include sp {
                margin-bottom: 0.5em;
              }
            }
          }
          a {
            color: $blue;
            span {
              text-decoration: underline;
            }
          }
        }
      }
    }
    h4 {
      margin-bottom: 15px;
    }
  }
  .ocw-movie-r1-wrapper, .ocw-movie-r2-wrapper {
    .ocw-items {
      @include tab_pc {
        @include grids(3, 30px, 20px);
      }
      li {
        @include row;
        position: relative;
        @include sp {
          @include notlast {
            margin-bottom: 12px;
          }
        }
        a {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          &:hover {
            background-color: rgba(255,255,255,0.2);
          }
        }
      }
      .ocw-image {
        float: left;
        width: 50%;
        padding-right: 12px;
      }
      .ocw-text {
        float: left;
        width: 50%;
        h5 {
          color: $blue;
          font-size: 17px;
          border-bottom: none;
          margin-bottom: 0;
          @include sp {
            font-size: 15px;
          }
          &:before {
            @include fa;
            content: '\f008';
          }
        }
      }
    }
  }
  .ocw-movie-r1-wrapper {
    @include tab_pc {
      padding: 25px 0;
    }
    border-width: 1px 0;
    border-style: solid;
    border-color: #B3C8E4;
    background-image: url('../images/bg_pattern_bluecheck.png');
    background-repeat: repeat;
    h4 {
      margin-bottom: 15px;
    }
    .ocw-movie-r1-box {
      &-title {
        border-radius: 5px 5px 0 0;
        border: 1px solid $lightblue;
        padding: 2px 20px;
        background-color: $lightblue;
        font-size: 18px;
        font-weight: bold;
        color: #FFF;
        &:before {
          @include fa;
          content: '\f07b';
        }
      }
      &-contents {
        padding: 20px;
        background-color: #FFF;
        border-radius: 0 0 5px 5px;
        border: 1px solid $lightblue;

      }
    }
  }
  .ocw-movie-r2-wrapper {
    background-image: url('../images/bg_pattern_stripe.png');
    background-repeat: repeat;
    .ocw-movie-r2-box {
      padding: 20px;
      background-color: #FFF;
      border: 1px solid #DDD;
      .ocw-category {
        &:before {
          @include fa;
          content: '\f07b';
          font-weight: bold;
        }
      }
    }
  }
}


.ocw-movie-index-course {
  @include video_grids;
  .ocw-contents-wrapper {
    .ocw-box {
      @include sp {
        padding: 15px;
      }
    }
  }
  .ocw-items-head {
    h2 {
      &:before {
        display: none;
      }
      small {
        display: block;
        font-size: 14px;
      }
    }
  }
  .ocw-item {
    h3 {
      border-left: none;
      padding-left: 0;
    }
  }
}

.ocw-movie-index {
  section {
    @include video_grids;
    padding: 25px 0;
    @include sp {
      padding: 15px 0;
    }
    &:nth-of-type(2n+1) {
      background-image: url('../images/bg_pattern_stripe.png');
      background-repeat: repeat;
    }
    &:nth-of-type(2n) {
      border-width: 1px 0;
      border-style: solid;
      border-color: #B3C8E4;
      background-image: url('../images/bg_pattern_bluecheck.png');
      background-repeat: repeat;
    }
    .ocw-items-head {
      @include row;
      @include tab_pc {
        margin-bottom: 20px;
      }
      @include sp {
        margin-bottom: 12px;
      }
      h2 {
        @include fa;
        font-size: 21px;
        font-weight: bold;
        line-height: 1.4;
        color: $blue;
        @include tab_pc {
          float: left;
        }
        &:before {
          content: '\f07b';
          margin-right: 0.2em;
        }
        small {
          font-size: 14px;
        }
      }
      .ocw-items-head-more {
        display: table;
        font-size: 12px;
        padding: 4px 8px;
        line-height: 1;
        border-radius: 3px;
        background-color: $blue;
        color: #FFF;
        @include tab_pc {
          float: right;
          margin-top: 4px;
        }
        @include sp {
          margin-top: 8px;
        }
        &:hover {
          background-color: $lightblue;
        }
        &:after {
          display: inline-block;
          vertical-align: middle;
          content: '';
          width: 0.6em;
          height: 0.6em;
          border-width: 3px 3px 0 0;
          border-style: solid;
          border-color: #FFF;
          margin-left: 0.2em;
          transform: translateY(-0.1em) rotate(45deg);
        }
      }
    }
  }
}

.ocw-search-result {
  @include video_grids;
  .ocw-items-head {
    h2 {
      @include sp {
        font-size: 18px;
        margin-bottom: 15px;
      }
      &:before {
        display: none;
      }
      small {
        display: block;
        font-size: 14px;
        @include sp {
          font-size: 12px;
        }
      }
    }
  }
  .ocw-item {
    h3 {
      border-left: none;
      padding-left: 0;
    }
  }
}

.ocw-article-index {
  .ocw-article-entry {
    @include row;
    position: relative;
    padding: 30px;
    background-color: #FFF;
    border: 1px solid #DDD;
    a {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: transparent;
      &:hover {
        background-color: rgba(255,255,255,0.2);
      }
    }
    @include sp {
      padding: 15px;
    }
    @include notlast {
      margin-bottom: 25px;
      @include sp {
        margin-bottom: 15px;
      }
    }
    h3 {
      margin-bottom: 5px;
      @include tab_pc {
        float: left;
        width: percentage(770 / 1110);
        padding-right: 30px;
      }
      @include sp {
        width: 100%;
        margin-bottom: 12px;
      }
    }
    .ocw-image {
      @include tab_pc {
        float: right;
        width: percentage(340 / 1110);
      }
      @include sp {
        width: 100%;
        margin-bottom: 10px;
      }
      img {
        width: 100%;
      }
    }
    .ocw-text {
      @include tab_pc {
        float: left;
        width: percentage(770 / 1110);
        padding-right: 30px;
      }
      @include sp {
        width: 100%;
      }
      .ocw-meta {
        margin-bottom: 10px;
        @include sp {
          margin-bottom: 5px;
        }
      }
    }
    &-noimage {
      .ocw-text {
        @include tab_pc {
          width: 100%;
        }
      }
    }
  }
}

.ocw-article {
  &-mainvisual {
    img {
      width: 100%;
    }
  }
  .ocw-contents {
    @include row;
    h3 {
      margin-bottom: 5px;
    }
    .ocw-meta {
      margin-bottom: 10px;
    }
    h5 {
      @include sp {
        margin-bottom: 10px;
      }
    }
    .ocw-outline {
      margin-bottom: 20px;
      @include tab_pc {
        float: left;
        width: percentage(770 / 1110);
        padding-right: 30px;
      }
      @include sp {
        width: 100%;
      }
    }
    .ocw-image {
      @include tab_pc {
        float: right;
        width: percentage(340 / 1110);
      }
      @include sp {
        width: 100%;
        margin-bottom: 20px;
      }
      img {
        width: 100%;
      }
    }
    .ocw-detail {
      @include tab_pc {
        float: left;
        width: percentage(770 / 1110);
        padding-right: 30px;
      }
      @include sp {
        width: 100%;
      }
      & > .ocw-ol {
        font-weight: bold;
        & > li {
          @include notlast {
            margin-bottom: 5px;
          }
        }
        .ocw-ul, p {
          font-weight: normal;
          margin-top: 5px;
          @include notlastchild {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
