@charset "UTF-8";
html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

/*
 * Typography
 */
/*
 * Colors
 */
/*
 * Buttons
 */
/*
 * Media Queries
 */
/*
 * Mixins
 */
@media screen and (min-width: 768px) {
  .sp_show {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sp_hide {
    display: none;
  }
}

html, body {
  margin: 0;
  padding: 0;
  font-family: "メイリオ", "Meiryo", "Hiragino Kaku Gothic Pro", sans-serif;
  color: #333;
  line-height: 1.85;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  html, body {
    line-height: 1.6;
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  body {
    padding-top: 56px;
  }
}

body * {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

li {
  list-style-type: none;
  list-style-position: inside;
}

a {
  text-decoration: none;
}

a:hover img {
  opacity: 0.8;
}

i {
  font-style: normal;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

select, button, input[type="submit"] {
  cursor: pointer;
}

.clearfix {
  display: block;
}

.clearfix::after {
  clear: both;
  content: "";
  display: block;
}

.ocw-inner {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1000px) {
  .ocw-inner {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 1000px) {
  .ocw-inner {
    max-width: 1000px;
  }
}

@media screen and (min-width: 1200px) {
  .ocw-inner {
    max-width: 1170px;
  }
}

.ocw-meta {
  font-size: 14px;
}

.ocw-meta ul {
  display: table;
}

.ocw-meta li {
  display: table-cell;
}

.ocw-meta li:not(:last-of-type) {
  padding-right: 1em;
}

.ocw-meta li:before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  font-weight: bold;
  font-size: 0.9em;
}

.ocw-meta li.ocw-date:before {
  content: "\f073";
}

.ocw-meta li.ocw-byline:before {
  content: "\f406";
}

.ocw-main .ocw-pagetitle {
  width: 100%;
  padding: 35px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-pagetitle {
    padding: 25px 0;
  }
}

.ocw-main .ocw-pagetitle-sky {
  background-image: url("../images/header_sky.jpg");
}

.ocw-main .ocw-pagetitle-green {
  background-image: url("../images/header_green.jpg");
}

.ocw-main .ocw-pagetitle h1 {
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
  line-height: 1.4;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-pagetitle h1 {
    font-size: 19px;
  }
}

.ocw-main .ocw-pagetitle h1 i {
  margin-right: 0.2em;
}

.ocw-main .ocw-contents-wrapper {
  padding: 30px 0;
  background-image: url("../images/bg_pattern_stripe.png");
  background-repeat: repeat;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper {
    padding: 20px 0;
  }
}

.ocw-main .ocw-contents-wrapper .ocw-contents {
  padding: 30px;
  background-color: #FFF;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper .ocw-contents {
    padding: 15px;
  }
}

.ocw-main .ocw-contents-wrapper h1, .ocw-main .ocw-contents-wrapper h2, .ocw-main .ocw-contents-wrapper h3, .ocw-main .ocw-contents-wrapper h4, .ocw-main .ocw-contents-wrapper h5, .ocw-main .ocw-contents-wrapper h6 {
  line-height: 1.4;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper h1 br, .ocw-main .ocw-contents-wrapper h2 br, .ocw-main .ocw-contents-wrapper h3 br, .ocw-main .ocw-contents-wrapper h4 br, .ocw-main .ocw-contents-wrapper h5 br, .ocw-main .ocw-contents-wrapper h6 br {
    display: none;
  }
}

.ocw-main .ocw-contents-wrapper h1 {
  padding: 12px 20px 8px;
  background-color: #E6E6E6;
  color: #333;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 35px;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper h1 {
    padding: 10px 15px 6px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper h1 {
    font-size: 21px;
  }
}

.ocw-main .ocw-contents-wrapper h1:not(:first-child) {
  margin-top: 35px;
}

.ocw-main .ocw-contents-wrapper h2 {
  margin-bottom: 25px;
  color: #4366B0;
  font-size: 21px;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper h2 {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.ocw-main .ocw-contents-wrapper h2:not(:first-child) {
  margin-top: 35px;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper h2:not(:first-child) {
    margin-top: 25px;
  }
}

.ocw-main .ocw-contents-wrapper h2:before {
  display: inline-block;
  vertical-align: middle;
  content: '';
  width: 0.9em;
  height: 0.9em;
  margin-right: 0.2em;
  margin-top: -0.15em;
  border-radius: 50%;
  background-color: #4366B0;
}

.ocw-main .ocw-contents-wrapper h3 {
  position: relative;
  padding-left: 8px;
  margin-bottom: 25px;
  color: #4366B0;
  font-size: 21px;
  font-weight: 700;
  border-left: 5px solid #4366B0;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper h3 {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.ocw-main .ocw-contents-wrapper h3:not(:first-child) {
  margin-top: 35px;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper h3:not(:first-child) {
    margin-top: 25px;
  }
}

.ocw-main .ocw-contents-wrapper h4 {
  padding: 10px 10px 8px 10px;
  margin-bottom: 25px;
  background-color: #08225E;
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper h4 {
    margin-bottom: 20px;
    margin-left: -15px;
    padding: 7px 15px 5px 15px;
    width: calc(100% + 30px);
  }
}

.ocw-main .ocw-contents-wrapper h4:not(:first-child) {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper h4:not(:first-child) {
    margin-top: 20px;
  }
}

.ocw-main .ocw-contents-wrapper h4:before {
  display: inline-block;
  vertical-align: middle;
  content: '';
  width: 6px;
  height: 1em;
  margin-right: 0.4em;
  margin-top: -0.1em;
  background-color: #4366B0;
}

.ocw-main .ocw-contents-wrapper h5 {
  margin-bottom: 25px;
  padding-bottom: 0.1em;
  border-bottom: 1px dotted #6D97CF;
  font-size: 18px;
  font-weight: bold;
  color: #6D97CF;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper h5 {
    margin-bottom: 15px;
    font-size: 16px;
  }
}

.ocw-main .ocw-contents-wrapper h5:not(:first-child) {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper h5:not(:first-child) {
    margin-top: 15px;
  }
}

.ocw-main .ocw-contents-wrapper h6 {
  margin-bottom: 25px;
  padding-bottom: 0.1em;
  border-bottom: 1px dotted #666;
  font-size: 18px;
  font-weight: bold;
  color: #666;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper h6 {
    margin-bottom: 20px;
    font-size: 16px;
  }
}

.ocw-main .ocw-contents-wrapper h6:not(:first-child) {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper h6:not(:first-child) {
    margin-top: 20px;
  }
}

.ocw-main .ocw-contents-wrapper p:not(:last-child) {
  margin-bottom: 20px;
}

.ocw-main .ocw-contents-wrapper .ocw-ul, .ocw-main .ocw-contents-wrapper .ocw-ol {
  padding-left: 1.5rem;
}

.ocw-main .ocw-contents-wrapper .ocw-ul:not(:last-child), .ocw-main .ocw-contents-wrapper .ocw-ol:not(:last-child) {
  margin-bottom: 20px;
}

.ocw-main .ocw-contents-wrapper .ocw-ul li, .ocw-main .ocw-contents-wrapper .ocw-ol li {
  list-style-position: outside;
}

.ocw-main .ocw-contents-wrapper .ocw-ul .ocw-ul, .ocw-main .ocw-contents-wrapper .ocw-ul .ocw-ol, .ocw-main .ocw-contents-wrapper .ocw-ul p, .ocw-main .ocw-contents-wrapper .ocw-ol .ocw-ul, .ocw-main .ocw-contents-wrapper .ocw-ol .ocw-ol, .ocw-main .ocw-contents-wrapper .ocw-ol p {
  padding-left: 1.5rem;
}

.ocw-main .ocw-contents-wrapper .ocw-ul-d > li {
  list-style-type: disc;
}

.ocw-main .ocw-contents-wrapper .ocw-ul-c > li {
  list-style-type: circle;
}

.ocw-main .ocw-contents-wrapper .ocw-ul-s > li {
  list-style-type: square;
}

.ocw-main .ocw-contents-wrapper .ocw-ol-d > li {
  list-style-type: decimal;
}

.ocw-main .ocw-contents-wrapper .ocw-ol-la > li {
  list-style-type: lower-alpha;
}

.ocw-main .ocw-contents-wrapper .ocw-ol-lg > li {
  list-style-type: lower-greek;
}

.ocw-main .ocw-contents-wrapper .ocw-ol-lr > li {
  list-style-type: lower-roman;
}

.ocw-main .ocw-contents-wrapper .ocw-ol-ua > li {
  list-style-type: upper-alpha;
}

.ocw-main .ocw-contents-wrapper .ocw-ol-ur > li {
  list-style-type: upper-roman;
}

.ocw-main .ocw-contents-wrapper .ocw-text-left {
  text-align-last: left;
}

.ocw-main .ocw-contents-wrapper .ocw-text-center {
  text-align: center;
}

.ocw-main .ocw-contents-wrapper .ocw-text-right {
  text-align: right;
}

.ocw-main .ocw-contents-wrapper .ocw-text-justify {
  text-align: justify;
  text-justify: inter-ideograph;
  text-align-last: justify;
}

.ocw-main .ocw-contents-wrapper .ocw-img-left {
  float: left;
  margin: 0 1em 1em 0;
}

.ocw-main .ocw-contents-wrapper .ocw-img-center {
  margin: 0 auto;
}

.ocw-main .ocw-contents-wrapper .ocw-img-right {
  float: right;
  margin: 0 0 1em 1em;
}

.ocw-main .ocw-contents-wrapper .ocw-box {
  padding: 30px 40px;
}

@media screen and (max-width: 767px) {
  .ocw-main .ocw-contents-wrapper .ocw-box {
    padding: 25px 30px;
  }
}

.ocw-main .ocw-contents-wrapper .ocw-box-white {
  background-color: #FFF;
  border: 1px solid #DDD;
}

.ocw-main .ocw-contents-wrapper .ocw-box-gray {
  background-color: #EEE;
}

.ocw-main .ocw-contents-wrapper .ocw-box-red {
  background-color: #FFF0F0;
  border: 1px solid #E6ACB0;
}

@media screen and (max-width: 1000px) {
  .ocw-main .ocw-contents-wrapper .ocw-table-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.ocw-main .ocw-contents-wrapper .ocw-table-wrapper table {
  width: 100%;
  min-width: 1000px;
  border-collapse: collapse;
}

.ocw-main .ocw-contents-wrapper .ocw-table-wrapper table, .ocw-main .ocw-contents-wrapper .ocw-table-wrapper table th, .ocw-main .ocw-contents-wrapper .ocw-table-wrapper table td {
  border-width: 1px;
  border-style: solid;
}

.ocw-main .ocw-contents-wrapper .ocw-table-wrapper table th, .ocw-main .ocw-contents-wrapper .ocw-table-wrapper table td {
  padding: 8px 20px;
  text-align: left;
}

.ocw-main .ocw-contents-wrapper .ocw-table-wrapper table thead th {
  font-weight: bold;
  background-color: #4366B0;
  color: #FFF;
  border-color: #4366B0;
}

.ocw-main .ocw-contents-wrapper .ocw-table-wrapper table thead th:not(:last-of-type) {
  border-right-color: #FFF;
}

.ocw-main .ocw-contents-wrapper .ocw-table-wrapper table tbody td {
  border-color: #CCC;
}

.ocw-main .ocw-contents-wrapper blockquote {
  padding: 40px 25px 30px;
  background-color: #F5F5F5;
  border-left: 5px solid #CCC;
  background-image: url("../images/blockquote.png");
  background-repeat: no-repeat;
  background-size: 34px 25px;
  background-position: 10px 10px;
}

.ocw-main .ocw-contents-wrapper strong {
  font-weight: bold;
}

.ocw-main .ocw-contents-wrapper strong strong {
  font-weight: normal;
  text-decoration: underline;
}

.ocw-main .ocw-contents-wrapper em {
  font-style: italic;
}

.ocw-main .ocw-contents-wrapper a {
  color: #4366B0;
  text-decoration: none;
}

.ocw-main .ocw-contents-wrapper a:hover {
  color: #6D97CF;
}

.ocw-main .ocw-contents-wrapper a:hover:before, .ocw-main .ocw-contents-wrapper a:hover:after {
  opacity: 0.8;
}

.ocw-main .ocw-contents-wrapper a:hover img {
  opacity: 0.8;
}

a.ocw-link-blank:after, a.ocw-link-pdf:after, a.ocw-link-doc:after, a.ocw-link-xls:after, a.ocw-link-ppt:after {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  margin-left: 0.5em;
  font-weight: bold;
  transform: translateY(-0.1em);
}

a.ocw-link-blank:after {
  color: #888;
  content: '\f35d';
  font-size: 0.8em;
}

a.ocw-link-pdf:after {
  color: #D11B12;
  content: '\f1c1';
}

a.ocw-link-doc:after {
  color: #2C5898;
  content: '\f1c2';
}

a.ocw-link-xls:after {
  color: #217346;
  content: '\f1c3';
}

a.ocw-link-ppt:after {
  color: #D34C27;
  content: '\f1c4';
}

.ocw-back-to-index, .ocw-index-more {
  padding: 25px 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: #B3C8E4;
  background-image: url("../images/bg_pattern_bluecheck.png");
  background-repeat: repeat;
}

@media screen and (max-width: 767px) {
  .ocw-back-to-index, .ocw-index-more {
    padding: 15px 0;
  }
}

.ocw-back-to-index a, .ocw-index-more a {
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  max-width: 570px;
  border-radius: 5px;
  line-height: 35px;
  background-color: #4366B0;
  color: #FFF;
  font-size: 14px;
}

.ocw-back-to-index a:hover, .ocw-index-more a:hover {
  background-color: #6D97CF;
}

.ocw-back-to-index a:before {
  display: inline-block;
  vertical-align: middle;
  content: '';
  width: 0.65em;
  height: 0.65em;
  border-width: 0 0 3px 3px;
  border-style: solid;
  border-color: #FFF;
  margin-right: 0.2em;
  transform: translateY(-0.1em) rotate(45deg);
}

.ocw-index-more a:after {
  display: inline-block;
  vertical-align: middle;
  content: '';
  width: 0.65em;
  height: 0.65em;
  border-width: 0 0 3px 3px;
  border-style: solid;
  border-color: #FFF;
  margin-left: 0.3em;
  transform: translateY(-0.2em) rotate(-45deg);
}

.ocw-pagination {
  margin-top: 35px;
  padding-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .ocw-pagination {
    margin-top: 20px;
    padding-bottom: 0;
  }
}

.ocw-pagination ul {
  display: table;
  margin: 0 auto;
}

.ocw-pagination li {
  display: table-cell;
  padding: 0 2px;
}

.ocw-pagination a, .ocw-pagination span {
  display: block;
  text-align: center;
  width: 30px;
  line-height: 28px;
  border-width: 1px;
  border-style: solid;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .ocw-pagination a, .ocw-pagination span {
    width: 28px;
    line-height: 26px;
  }
}

.ocw-pagination a {
  background-color: #FFF;
  border-color: #CCC;
  color: #AAA !important;
}

.ocw-pagination-current, .ocw-pagination a:hover {
  background-color: #4366B0;
  border-color: #6D97CF;
  color: #FFF !important;
}

.ocw-pagination-prev:after, .ocw-pagination-next:after {
  display: inline-block;
  content: '';
  border-style: solid;
  border-width: 5px 6px;
  border-color: transparent;
}

.ocw-pagination-prev:after {
  border-right-color: #BBB;
  transform: translate(-25%, 12.5%);
}

.ocw-pagination-prev:hover:after {
  border-right-color: #FFF;
  opacity: 1 !important;
}

.ocw-pagination-next:after {
  border-left-color: #BBB;
  transform: translate(25%, 12.5%);
}

.ocw-pagination-next:hover:after {
  border-left-color: #FFF;
  opacity: 1 !important;
}

@media screen and (min-width: 1000px) {
  .ocw-footer {
    padding-top: 40px;
    padding-bottom: 40px;
    line-height: 1.5;
  }
}

@media screen and (max-width: 1000px) {
  .ocw-footer {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 1.4;
  }
}

.ocw-footer .ocw-scrolltop {
  position: fixed;
  z-index: 10000;
  display: block;
  right: 45px;
  bottom: 90px;
  width: 49px;
  height: 49px;
  background-image: url("../images/scrolltop.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  opacity: 0;
  transition: opacity 0.5s linear;
}

@media screen and (max-width: 1000px) {
  .ocw-footer .ocw-scrolltop {
    right: 15px;
    bottom: 15px;
    width: 40px;
    height: 40px;
  }
}

.ocw-footer .ocw-scrolltop.active {
  opacity: 1;
}

.ocw-footer .ocw-inner {
  width: 100%;
}

@media screen and (min-width: 1000px) {
  .ocw-footer .ocw-inner {
    display: table;
  }
}

@media screen and (min-width: 1000px) {
  .ocw-footer .ocw-logo {
    display: table-cell;
    vertical-align: middle;
  }
}

@media screen and (max-width: 1000px) {
  .ocw-footer .ocw-logo {
    margin-bottom: 10px;
  }
}

.ocw-footer .ocw-logo a {
  display: block;
}

.ocw-footer .ocw-logo a img {
  height: 80px;
}

@media screen and (max-width: 1000px) {
  .ocw-footer .ocw-logo a img {
    height: 46px;
  }
}

@media screen and (min-width: 1000px) {
  .ocw-footer .ocw-address {
    display: table-cell;
    vertical-align: middle;
    padding-left: 25px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  .ocw-footer .ocw-address {
    margin-bottom: 20px;
    font-size: 12px;
  }
}

.ocw-footer .ocw-address p:not(:last-of-type) {
  margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
  .ocw-footer .ocw-address p:not(:last-of-type) {
    margin-bottom: 5px;
  }
}

.ocw-footer .ocw-address p .ocw-email {
  color: #4366B0;
}

.ocw-footer .ocw-address p .ocw-email:hover {
  color: #6D97CF;
}

.ocw-footer .ocw-address p .ocw-email:before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  content: '\f0e0';
  font-weight: bold;
}

@media screen and (min-width: 1000px) {
  .ocw-footer .ocw-misc {
    display: table-cell;
    vertical-align: bottom;
    text-align: right;
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  .ocw-footer .ocw-misc {
    font-size: 12px;
  }
}

.ocw-footer .ocw-misc .ocw-links {
  display: table;
}

@media screen and (min-width: 1000px) {
  .ocw-footer .ocw-misc .ocw-links {
    margin: 0 0 5px auto;
  }
}

@media screen and (max-width: 1000px) {
  .ocw-footer .ocw-misc .ocw-links {
    margin-bottom: 5px;
  }
}

.ocw-footer .ocw-misc .ocw-links ul {
  display: table;
}

.ocw-footer .ocw-misc .ocw-links li {
  display: table-cell;
}

.ocw-footer .ocw-misc .ocw-links li:not(:last-of-type) {
  padding-right: 1.5em;
}

.ocw-footer .ocw-misc .ocw-links li a {
  color: #222;
}

.ocw-footer .ocw-misc .ocw-copyright {
  font-size: 12px;
}

@media screen and (max-width: 1000px) {
  .ocw-footer .ocw-misc .ocw-copyright {
    font-size: 11px;
  }
}

.ocw-header {
  width: 100%;
  background-color: #FFF;
}

@media screen and (max-width: 767px) {
  .ocw-header {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
  }
  .ocw-header, .ocw-header .ocw-search, .ocw-header .ocw-course-search, .ocw-header .ocw-gnav {
    transition: all 0.75s ease-in-out;
  }
  .ocw-header.hidden {
    transform: translateY(-100%);
  }
  .ocw-header.hidden .ocw-search, .ocw-header.hidden .ocw-course-search, .ocw-header.hidden .ocw-gnav {
    opacity: 0;
  }
}

.ocw-header .ocw-upper {
  display: block;
  padding-top: 20px;
  padding-bottom: 15px;
}

.ocw-header .ocw-upper::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-upper {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.ocw-header .ocw-upper .ocw-inner {
  display: block;
}

.ocw-header .ocw-upper .ocw-inner::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-upper .ocw-inner {
    padding-right: 0;
    height: 56px;
  }
}

.ocw-header .ocw-upper .ocw-site-title {
  float: left;
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-upper .ocw-site-title {
    padding-top: 14px;
  }
}

.ocw-header .ocw-upper .ocw-site-title a {
  display: block;
}

.ocw-header .ocw-upper .ocw-site-title a img {
  height: 60px;
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-upper .ocw-site-title a img {
    height: 32px;
  }
}

@media screen and (min-width: 768px) {
  .ocw-header .ocw-upper .ocw-misc {
    float: right;
    display: table;
  }
}

@media screen and (min-width: 768px) {
  .ocw-header .ocw-upper .ocw-misc .ocw-search {
    display: table-cell;
    vertical-align: middle;
  }
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-upper .ocw-misc .ocw-search {
    position: fixed;
    z-index: 10000;
    width: 100%;
    left: 0;
    top: 56px;
    background-color: #EEE;
    display: none;
    padding: 15px;
    background-color: #EEE;
  }
}

.ocw-header .ocw-upper .ocw-misc .ocw-search input {
  float: left;
  height: 35px;
  padding: 0 0.5em;
  border: 1px solid #768FC5;
  background-color: #FFF;
}

@media screen and (min-width: 768px) {
  .ocw-header .ocw-upper .ocw-misc .ocw-search input {
    width: 240px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-upper .ocw-misc .ocw-search input {
    width: calc(100% - 10px - 38px);
    margin-right: 10px;
  }
}

.ocw-header .ocw-upper .ocw-misc .ocw-search button {
  float: left;
  text-align: center;
  width: 38px;
  height: 35px;
  line-height: 35px;
  background-color: #4366B0;
  color: #FFF;
  border-radius: 5px;
}

.ocw-header .ocw-upper .ocw-misc .ocw-search button:after {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  content: '\f002';
  font-weight: bold;
  padding-top: 1px;
  margin-right: -1px;
}

.ocw-header .ocw-upper .ocw-misc .ocw-logo {
  display: table-cell;
  vertical-align: middle;
  padding-left: 25px;
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-upper .ocw-misc .ocw-logo {
    display: none;
  }
}

.ocw-header .ocw-upper .ocw-misc .ocw-logo a {
  display: block;
}

.ocw-header .ocw-upper .ocw-misc .ocw-logo a img {
  height: 52px;
}

.ocw-header .ocw-upper .ocw-misc-sp {
  float: right;
  display: table;
}

@media screen and (min-width: 768px) {
  .ocw-header .ocw-upper .ocw-misc-sp {
    display: none;
  }
}

.ocw-header .ocw-upper .ocw-misc-sp .ocw-search-sw, .ocw-header .ocw-upper .ocw-misc-sp .ocw-gnav-sw {
  display: table-cell;
  vertical-align: top;
  text-align: center;
}

.ocw-header .ocw-upper .ocw-misc-sp .ocw-search-sw a, .ocw-header .ocw-upper .ocw-misc-sp .ocw-gnav-sw a {
  display: block;
  text-align: center;
  font-size: 10px;
  line-height: 1;
  color: #4366B0;
  width: 56px;
  height: 56px;
  padding: 13px 0;
}

.ocw-header .ocw-upper .ocw-misc-sp .ocw-search-sw a.active, .ocw-header .ocw-upper .ocw-misc-sp .ocw-gnav-sw a.active {
  background-color: #EEE;
}

.ocw-header .ocw-upper .ocw-misc-sp .ocw-search-sw a:before, .ocw-header .ocw-upper .ocw-misc-sp .ocw-gnav-sw a:before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  display: block;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 3px;
}

.ocw-header .ocw-upper .ocw-misc-sp .ocw-search-sw a:before {
  content: '\f002';
}

.ocw-header .ocw-upper .ocw-misc-sp .ocw-gnav-sw a:before {
  content: '\f0c9';
}

.ocw-header .ocw-lower {
  display: block;
  background-color: #FFF;
  padding-bottom: 15px;
}

.ocw-header .ocw-lower::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-lower {
    padding-bottom: 0;
  }
}

.ocw-header .ocw-lower .ocw-gnav {
  float: left;
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-lower .ocw-gnav {
    display: none;
    position: fixed;
    z-index: 10000;
    right: 0;
    top: 56px;
    background-color: #EEE;
    width: 185px;
  }
}

@media screen and (min-width: 768px) {
  .ocw-header .ocw-lower .ocw-gnav > ul {
    display: table;
  }
}

@media screen and (min-width: 768px) {
  .ocw-header .ocw-lower .ocw-gnav > ul > li {
    display: table-cell;
  }
  .ocw-header .ocw-lower .ocw-gnav > ul > li:not(:last-of-type) {
    padding-right: 1.25em;
  }
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-lower .ocw-gnav > ul > li {
    padding: 5px 15px;
  }
  .ocw-header .ocw-lower .ocw-gnav > ul > li:not(:last-of-type) {
    border-bottom: 1px solid #CCC;
  }
}

.ocw-header .ocw-lower .ocw-gnav > ul > li.ocw-gnav01 a:before {
  content: '\f05a';
}

.ocw-header .ocw-lower .ocw-gnav > ul > li.ocw-gnav02 a:before {
  content: '\f5da';
}

.ocw-header .ocw-lower .ocw-gnav > ul > li.ocw-gnav03 a:before {
  content: '\f0ac';
}

.ocw-header .ocw-lower .ocw-gnav > ul > li.ocw-gnav04 a:before {
  content: '\f02d';
}

.ocw-header .ocw-lower .ocw-gnav > ul > li.ocw-gnav05 a:before {
  content: '\f002';
}

.ocw-header .ocw-lower .ocw-gnav > ul > li a {
  color: #4366B0;
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-lower .ocw-gnav > ul > li a {
    font-size: 16px;
  }
}

.ocw-header .ocw-lower .ocw-gnav > ul > li a:hover {
  color: #6D97CF;
}

.ocw-header .ocw-lower .ocw-gnav > ul > li a:before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
}

@media screen and (min-width: 768px) {
  .ocw-header .ocw-lower .ocw-gnav > ul > li.sp_show {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-lower .ocw-gnav > ul > li.sp_show {
    display: block;
    padding: 8px 15px;
  }
  .ocw-header .ocw-lower .ocw-gnav > ul > li.sp_show::after {
    clear: both;
    content: "";
    display: block;
  }
}

.ocw-header .ocw-lower .ocw-gnav > ul > li.sp_show .ocw-logo {
  float: left;
  height: 23px;
}

.ocw-header .ocw-lower .ocw-gnav > ul > li.sp_show .ocw-lang {
  float: right;
}

.ocw-header .ocw-lower .ocw-lang {
  float: right;
}

.ocw-header .ocw-lower .ocw-lang ul {
  display: table;
}

.ocw-header .ocw-lower .ocw-lang ul li {
  display: table-cell;
}

.ocw-header .ocw-lower .ocw-lang ul li.ocw-active a {
  background-color: #4366B0;
  color: #FFF;
}

.ocw-header .ocw-lower .ocw-lang ul li a {
  display: block;
  width: 64px;
  text-align: center;
  padding-top: 1px;
  background-color: #BBB;
  color: #888;
  font-size: 14px;
  font-weight: bold;
  line-height: 23px;
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-lower .ocw-lang ul li a {
    font-size: 13px;
    width: 60px;
  }
}

.ocw-header .ocw-course-search {
  background-color: #4366B0;
}

@media screen and (min-width: 768px) {
  .ocw-header .ocw-course-search {
    padding: 10px 0;
  }
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-course-search {
    position: fixed;
    z-index: 10000;
    display: none;
    padding: 15px 0;
    width: 100%;
    left: 0;
    top: 121px;
  }
}

@media screen and (min-width: 768px) {
  .ocw-header .ocw-course-search dl {
    display: table;
  }
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-course-search dl {
    display: block;
  }
  .ocw-header .ocw-course-search dl::after {
    clear: both;
    content: "";
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .ocw-header .ocw-course-search dl dt {
    display: table-cell;
    vertical-align: middle;
    padding-right: 17px;
  }
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-course-search dl dt {
    margin-bottom: 8px;
  }
}

.ocw-header .ocw-course-search dl dt span {
  position: relative;
  display: block;
  text-align: center;
  padding-left: 3px;
  font-size: 13px;
  width: 82px;
  line-height: 26px;
  background-color: #27488F;
  color: #FFF;
}

.ocw-header .ocw-course-search dl dt span:after {
  position: absolute;
  display: block;
  content: '';
  border-width: 13px 7px;
  border-style: solid;
  border-color: transparent;
  border-left-color: #27488F;
  top: 0;
  left: 100%;
}

@media screen and (min-width: 768px) {
  .ocw-header .ocw-course-search dl dd {
    display: table-cell;
    vertical-align: middle;
  }
  .ocw-header .ocw-course-search dl dd:not(:last-of-type) {
    padding-right: 5px;
  }
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-course-search dl dd {
    float: left;
  }
  .ocw-header .ocw-course-search dl dd:nth-of-type(1) {
    width: calc(100% - 8px - 45px);
    margin-bottom: 8px;
  }
  .ocw-header .ocw-course-search dl dd:nth-of-type(2) {
    margin-right: 8px;
  }
  .ocw-header .ocw-course-search dl dd:nth-of-type(2), .ocw-header .ocw-course-search dl dd:nth-of-type(3) {
    width: calc((100% - 8px - 8px - 45px) / 2);
  }
  .ocw-header .ocw-course-search dl dd:nth-of-type(4) {
    padding-left: 8px;
  }
}

.ocw-header .ocw-course-search dl dd .ocw-select-wrapper {
  position: relative;
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-course-search dl dd .ocw-select-wrapper {
    width: 100%;
  }
}

.ocw-header .ocw-course-search dl dd .ocw-select-wrapper:after {
  pointer-events: none;
  position: absolute;
  display: block;
  content: '';
  right: 10px;
  width: 10px;
  height: 10px;
  top: 50%;
  transform: translateY(-75%) rotate(45deg);
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #4366B0;
}

.ocw-header .ocw-course-search dl dd select, .ocw-header .ocw-course-search dl dd option {
  background-color: #FFF;
  height: 30px;
}

.ocw-header .ocw-course-search dl dd select {
  text-indent: 2px;
}

@media screen and (min-width: 768px) {
  .ocw-header .ocw-course-search dl dd select {
    min-width: 11em;
  }
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-course-search dl dd select {
    width: 100%;
  }
}

.ocw-header .ocw-course-search dl dd option {
  min-width: 10em;
  padding-right: 1em;
}

@media screen and (max-width: 767px) {
  .ocw-header .ocw-course-search dl dd option {
    width: 100%;
  }
}

.ocw-header .ocw-course-search dl dd input[type="reset"] {
  border: 1px solid #7798CF;
  background-color: transparent;
  border-radius: 5px;
  text-align: center;
  color: #7798CF;
  width: 45px;
  line-height: 30px;
  font-size: 11px;
}

.ocw-home {
  overflow-x: hidden;
}

.ocw-home .ocw-slides {
  position: relative;
  background-color: #FFF;
  padding-top: 30px;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

@media screen and (max-width: 1000px) {
  .ocw-home .ocw-slides {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 20px;
  }
}

.ocw-home .ocw-slides .ocw-inner {
  position: relative;
}

@media screen and (max-width: 1000px) {
  .ocw-home .ocw-slides .ocw-inner {
    padding-left: 0;
    padding-right: 0;
  }
}

.ocw-home .ocw-slides:after {
  position: absolute;
  display: block;
  z-index: 1;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  opacity: 0.4;
}

.ocw-home .ocw-slides .ocw-items {
  position: relative;
  z-index: 2;
}

.ocw-home .ocw-slides .ocw-items ul .ocw-item {
  width: 100%;
}

@media screen and (min-width: 1000px) {
  .ocw-home .ocw-slides .ocw-items ul .ocw-item {
    display: table;
    table-layout: fixed;
  }
}

.ocw-home .ocw-slides .ocw-items ul .ocw-item .ocw-video {
  position: relative;
}

@media screen and (min-width: 1000px) {
  .ocw-home .ocw-slides .ocw-items ul .ocw-item .ocw-video {
    display: table-cell;
    vertical-align: middle;
    width: 65.81197%;
    padding-top: 37.01923%;
  }
}

@media screen and (max-width: 1000px) {
  .ocw-home .ocw-slides .ocw-items ul .ocw-item .ocw-video {
    width: 100%;
    padding-top: 56.25%;
  }
}

.ocw-home .ocw-slides .ocw-items ul .ocw-item .ocw-video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 0;
}

.ocw-home .ocw-slides .ocw-items ul .ocw-item .ocw-text {
  background-color: rgba(255, 255, 255, 0.75);
}

@media screen and (min-width: 1000px) {
  .ocw-home .ocw-slides .ocw-items ul .ocw-item .ocw-text {
    display: table-cell;
    vertical-align: middle;
    width: 34.18803%;
    padding: 0 30px;
  }
}

@media screen and (max-width: 1000px) {
  .ocw-home .ocw-slides .ocw-items ul .ocw-item .ocw-text {
    padding: 20px 20px 35px;
  }
}

.ocw-home .ocw-slides .ocw-items ul .ocw-item .ocw-text .ocw-title {
  text-shadow: 0 0 1px #FFF, 0 0 1px #FFF, 0 0 2px #FFF, 0 0 2px #FFF, 0 0 2px #FFF, 0 0 3px #FFF, 0 0 3px #FFF, 0 0 3px #FFF, 0 0 4px #FFF, 0 0 4px #FFF, 0 0 4px #FFF, 0 0 5px #FFF, 0 0 5px #FFF, 0 0 5px #FFF;
  font-size: 21px;
  font-weight: bold;
  line-height: 1.25;
  margin-bottom: 5px;
}

.ocw-home .ocw-slides .ocw-items ul .ocw-item .ocw-text .ocw-title a {
  color: #08225E;
}

.ocw-home .ocw-slides .ocw-items ul .ocw-item .ocw-text .ocw-title a:hover {
  color: #4366B0;
}

.ocw-home .ocw-slides .ocw-items ul .ocw-item .ocw-text .ocw-meta {
  text-shadow: 0 0 1px #FFF, 0 0 1px #FFF, 0 0 2px #FFF, 0 0 2px #FFF, 0 0 2px #FFF, 0 0 3px #FFF, 0 0 3px #FFF, 0 0 3px #FFF, 0 0 4px #FFF, 0 0 4px #FFF, 0 0 4px #FFF, 0 0 5px #FFF, 0 0 5px #FFF, 0 0 5px #FFF;
  margin-bottom: 15px;
  color: #08225E;
}

.ocw-home .ocw-slides .ocw-items ul .ocw-item .ocw-text .ocw-content {
  color: #222;
  font-size: 16px;
  line-height: 1.6;
}

.ocw-home .ocw-slides .swiper-pagination {
  left: 50%;
  line-height: 1;
}

@media screen and (min-width: 1000px) {
  .ocw-home .ocw-slides .swiper-pagination {
    top: 100%;
    bottom: auto;
    transform: translate(-50%, 15px);
  }
}

@media screen and (max-width: 1000px) {
  .ocw-home .ocw-slides .swiper-pagination {
    top: auto;
    bottom: 12px;
    transform: translateX(-50%);
  }
}

.ocw-home .ocw-slides .swiper-pagination .swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  background-color: #999;
  opacity: 1;
}

.ocw-home .ocw-slides .swiper-pagination .swiper-pagination-bullet:not(:last-of-type) {
  margin-right: 9px;
}

.ocw-home .ocw-slides .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #111;
}

.ocw-home .ocw-slides .swiper-button-next, .ocw-home .ocw-slides .swiper-button-prev {
  display: block;
  content: '';
  width: 30px;
  height: 30px;
  margin-top: -15px;
  background-image: none;
  border-style: solid;
  border-color: #FFF;
  transform: rotate(45deg);
}

.ocw-home .ocw-slides .swiper-button-next {
  right: auto;
  left: 100%;
  border-width: 5px 5px 0 0;
}

.ocw-home .ocw-slides .swiper-button-prev {
  right: 100%;
  left: auto;
  border-width: 0 0 5px 5px;
}

.ocw-home .ocw-recent, .ocw-home .ocw-news {
  background-image: url("../images/bg_pattern_stripe.png");
  background-repeat: repeat;
}

.ocw-home .ocw-recommend {
  border-width: 1px 0;
  border-style: solid;
  border-color: #B3C8E4;
  background-image: url("../images/bg_pattern_bluecheck.png");
  background-repeat: repeat;
}

.ocw-home .ocw-recent, .ocw-home .ocw-recommend, .ocw-home .ocw-news {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .ocw-home .ocw-recent, .ocw-home .ocw-recommend, .ocw-home .ocw-news {
    padding-top: 0;
    padding-bottom: 20px;
  }
}

.ocw-home .ocw-recent h2, .ocw-home .ocw-recommend h2, .ocw-home .ocw-news h2 {
  position: relative;
  padding: 10px 20px 6px;
  margin-bottom: 20px;
  background-color: #08225E;
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .ocw-home .ocw-recent h2, .ocw-home .ocw-recommend h2, .ocw-home .ocw-news h2 {
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 8px 25px;
  }
}

.ocw-home .ocw-recent h2:before, .ocw-home .ocw-recommend h2:before, .ocw-home .ocw-news h2:before {
  position: absolute;
  display: block;
  content: '';
  background-color: #4366B0;
  width: 5px;
  height: 17px;
  left: 9px;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .ocw-home .ocw-recent h2:before, .ocw-home .ocw-recommend h2:before, .ocw-home .ocw-news h2:before {
    left: 15px;
  }
}

@media screen and (min-width: 1000px) {
  .ocw-home .ocw-recent .ocw-items > ul, .ocw-home .ocw-recommend .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -30px;
  }
  .ocw-home .ocw-recent .ocw-items > ul > li, .ocw-home .ocw-recommend .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 30px * (3 - 1)) / 3) - 0.05px);
    max-width: calc(((100% - 30px * (3 - 1)) / 3) - 0.05px);
    margin-bottom: 30px;
  }
  .ocw-home .ocw-recent .ocw-items > ul > li:not(:nth-of-type(3n)), .ocw-home .ocw-recommend .ocw-items > ul > li:not(:nth-of-type(3n)) {
    margin-right: 30px;
  }
  .ocw-home .ocw-recent .ocw-items > ul > li a, .ocw-home .ocw-recommend .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-home .ocw-recent .ocw-items > ul > li a:hover, .ocw-home .ocw-recommend .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .ocw-home .ocw-recent .ocw-items > ul, .ocw-home .ocw-recommend .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }
  .ocw-home .ocw-recent .ocw-items > ul > li, .ocw-home .ocw-recommend .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 20px * (3 - 1)) / 3) - 0.05px);
    max-width: calc(((100% - 20px * (3 - 1)) / 3) - 0.05px);
    margin-bottom: 20px;
  }
  .ocw-home .ocw-recent .ocw-items > ul > li:not(:nth-of-type(3n)), .ocw-home .ocw-recommend .ocw-items > ul > li:not(:nth-of-type(3n)) {
    margin-right: 20px;
  }
  .ocw-home .ocw-recent .ocw-items > ul > li a, .ocw-home .ocw-recommend .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-home .ocw-recent .ocw-items > ul > li a:hover, .ocw-home .ocw-recommend .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media screen and (max-width: 767px) {
  .ocw-home .ocw-recent .ocw-items > ul, .ocw-home .ocw-recommend .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -15px;
  }
  .ocw-home .ocw-recent .ocw-items > ul > li, .ocw-home .ocw-recommend .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 0px * (1 - 1)) / 1) - 0.05px);
    max-width: calc(((100% - 0px * (1 - 1)) / 1) - 0.05px);
    margin-bottom: 15px;
  }
  .ocw-home .ocw-recent .ocw-items > ul > li:not(:nth-of-type(1n)), .ocw-home .ocw-recommend .ocw-items > ul > li:not(:nth-of-type(1n)) {
    margin-right: 0px;
  }
  .ocw-home .ocw-recent .ocw-items > ul > li a, .ocw-home .ocw-recommend .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-home .ocw-recent .ocw-items > ul > li a:hover, .ocw-home .ocw-recommend .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.ocw-home .ocw-recent .ocw-items .ocw-item, .ocw-home .ocw-recommend .ocw-items .ocw-item {
  position: relative;
  background-color: #FFF;
}

.ocw-home .ocw-recent .ocw-items .ocw-item .ocw-video img, .ocw-home .ocw-recommend .ocw-items .ocw-item .ocw-video img {
  width: 100%;
}

.ocw-home .ocw-recent .ocw-items .ocw-item .ocw-text, .ocw-home .ocw-recommend .ocw-items .ocw-item .ocw-text {
  padding: 15px 10px;
}

@media screen and (max-width: 767px) {
  .ocw-home .ocw-recent .ocw-items .ocw-item .ocw-text, .ocw-home .ocw-recommend .ocw-items .ocw-item .ocw-text {
    padding: 10px 10px;
  }
}

.ocw-home .ocw-recent .ocw-items .ocw-item .ocw-text .ocw-title, .ocw-home .ocw-recommend .ocw-items .ocw-item .ocw-text .ocw-title {
  color: #4366B0;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .ocw-home .ocw-recent .ocw-items .ocw-item .ocw-text .ocw-title, .ocw-home .ocw-recommend .ocw-items .ocw-item .ocw-text .ocw-title {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 2px;
  }
}

.ocw-home .ocw-recent .ocw-items .ocw-item .ocw-text .ocw-title:before, .ocw-home .ocw-recommend .ocw-items .ocw-item .ocw-text .ocw-title:before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  content: '\f008';
  font-size: 1.3em;
  vertical-align: middle;
}

.ocw-home .ocw-news h2 .ocw-more {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #FFF;
}

.ocw-home .ocw-news h2 .ocw-more:after {
  display: inline-block;
  content: '';
  border-width: 3px 3px 0 0;
  border-style: solid;
  border-color: #FFF;
  width: 0.6em;
  height: 0.6em;
  transform: rotate(45deg);
  margin-left: 0.25em;
}

@media screen and (min-width: 1000px) {
  .ocw-home .ocw-news .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }
  .ocw-home .ocw-news .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 30px * (2 - 1)) / 2) - 0.05px);
    max-width: calc(((100% - 30px * (2 - 1)) / 2) - 0.05px);
    margin-bottom: 20px;
  }
  .ocw-home .ocw-news .ocw-items > ul > li:not(:nth-of-type(2n)) {
    margin-right: 30px;
  }
  .ocw-home .ocw-news .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-home .ocw-news .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .ocw-home .ocw-news .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -15px;
  }
  .ocw-home .ocw-news .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 15px * (2 - 1)) / 2) - 0.05px);
    max-width: calc(((100% - 15px * (2 - 1)) / 2) - 0.05px);
    margin-bottom: 15px;
  }
  .ocw-home .ocw-news .ocw-items > ul > li:not(:nth-of-type(2n)) {
    margin-right: 15px;
  }
  .ocw-home .ocw-news .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-home .ocw-news .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media screen and (max-width: 767px) {
  .ocw-home .ocw-news .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -15px;
  }
  .ocw-home .ocw-news .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 0 * (1 - 1)) / 1) - 0.05px);
    max-width: calc(((100% - 0 * (1 - 1)) / 1) - 0.05px);
    margin-bottom: 15px;
  }
  .ocw-home .ocw-news .ocw-items > ul > li:not(:nth-of-type(1n)) {
    margin-right: 0;
  }
  .ocw-home .ocw-news .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-home .ocw-news .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.ocw-home .ocw-news .ocw-items .ocw-item {
  padding: 15px 20px;
  background-color: #FFF;
  border: 2px solid #DDD;
}

.ocw-home .ocw-news .ocw-items .ocw-item .ocw-title {
  color: #4366B0;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.4;
}

.ocw-home .ocw-news .ocw-items .ocw-item .ocw-content {
  font-size: 14px;
  line-height: 1.6;
}

.ocw-home .ocw-banners {
  padding-top: 30px;
  padding-bottom: 30px;
  display: block;
  background-image: url("../images/img_link_bg.jpg");
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
}

@media screen and (min-width: 1000px) {
  .ocw-home .ocw-banners .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -30px;
  }
  .ocw-home .ocw-banners .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 30px * (6 - 1)) / 6) - 0.05px);
    max-width: calc(((100% - 30px * (6 - 1)) / 6) - 0.05px);
    margin-bottom: 30px;
  }
  .ocw-home .ocw-banners .ocw-items > ul > li:not(:nth-of-type(6n)) {
    margin-right: 30px;
  }
  .ocw-home .ocw-banners .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-home .ocw-banners .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .ocw-home .ocw-banners .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -15px;
  }
  .ocw-home .ocw-banners .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 15px * (6 - 1)) / 6) - 0.05px);
    max-width: calc(((100% - 15px * (6 - 1)) / 6) - 0.05px);
    margin-bottom: 15px;
  }
  .ocw-home .ocw-banners .ocw-items > ul > li:not(:nth-of-type(6n)) {
    margin-right: 15px;
  }
  .ocw-home .ocw-banners .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-home .ocw-banners .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media screen and (max-width: 767px) {
  .ocw-home .ocw-banners .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;
  }
  .ocw-home .ocw-banners .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 10px * (3 - 1)) / 3) - 0.05px);
    max-width: calc(((100% - 10px * (3 - 1)) / 3) - 0.05px);
    margin-bottom: 10px;
  }
  .ocw-home .ocw-banners .ocw-items > ul > li:not(:nth-of-type(3n)) {
    margin-right: 10px;
  }
  .ocw-home .ocw-banners .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-home .ocw-banners .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.ocw-home .ocw-banners .ocw-items li {
  background-color: #FFF;
}

.ocw-home .ocw-banners .ocw-items img {
  width: 100%;
}

.ocw-home .ocw-banners .ocw-items a {
  position: static !important;
  display: block;
}

.ocw-css .ocw-contents-wrapper {
  background-image: none;
}

.ocw-css section:not(:last-of-type) {
  margin-bottom: 40px;
}

.ocw-css .ocw-box:not(:first-child) {
  margin-top: 20px;
}

.ocw-css .ocw-box:not(:last-child) {
  margin-bottom: 20px;
}

.ocw-movie .ocw-contents-wrapper {
  background-image: none;
  padding-top: 0;
  padding-bottom: 0;
}

.ocw-movie .ocw-movie-player-wrapper, .ocw-movie .ocw-movie-info-wrapper,
.ocw-movie .ocw-movie-r1-wrapper, .ocw-movie .ocw-movie-r2-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-player-wrapper, .ocw-movie .ocw-movie-info-wrapper,
  .ocw-movie .ocw-movie-r1-wrapper, .ocw-movie .ocw-movie-r2-wrapper {
    padding-top: 0;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-player-wrapper {
    padding-top: 15px;
  }
}

.ocw-movie .ocw-movie-player-wrapper h2 {
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-player-wrapper h2 {
    margin-bottom: 10px;
  }
}

.ocw-movie .ocw-movie-player-wrapper h2:before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  background-color: transparent;
  vertical-align: baseline;
}

.ocw-movie .ocw-movie-player-wrapper .ocw-inner {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .ocw-movie .ocw-movie-player-wrapper .ocw-inner {
    display: flex;
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 768px) {
  .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player {
    align-self: stretch;
    width: 65.81197%;
  }
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player {
    width: 100%;
    margin-bottom: 15px;
  }
}

.ocw-movie .ocw-movie-player-wrapper .ocw-movie-player h2:before {
  content: '\f008';
}

.ocw-movie .ocw-movie-player-wrapper .ocw-movie-player .ocw-movie-player-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player .ocw-movie-player-container {
    width: calc(100% + 30px);
    padding-top: calc((100% + 30px) * 0.5625);
    margin-left: -15px;
  }
}

.ocw-movie .ocw-movie-player-wrapper .ocw-movie-player .ocw-movie-player-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

@media screen and (min-width: 768px) {
  .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list {
    align-self: stretch;
    width: 34.18803%;
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list {
    width: 100%;
  }
}

.ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list h2:before {
  content: '\f46d';
}

.ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li {
  position: relative;
  display: flex;
  width: 100%;
  padding: 9px 0;
  background-color: #DDD;
}

.ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li.ocw-active, .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li:hover {
  background-color: #EEE;
}

.ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li.ocw-active .ocw-image, .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li:hover .ocw-image {
  background-image: url("../images/player_list_arrow.png");
}

@media screen and (min-width: 768px) {
  .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li.ocw-active .ocw-image, .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li:hover .ocw-image {
    background-position: 10px 50%;
    background-size: 12px auto;
  }
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li.ocw-active .ocw-image, .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li:hover .ocw-image {
    background-position: 8px 50%;
    background-size: 10px auto;
  }
}

.ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li a {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: transparent;
}

.ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li .ocw-image {
  align-self: center;
  width: 50%;
  padding-left: 30px;
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li .ocw-image {
    padding-left: 25px;
  }
}

.ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li .ocw-text {
  align-self: flex-start;
  width: 50%;
  left: 12px;
  font-size: 13px;
  padding-left: 12px;
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li .ocw-text {
    font-size: 12px;
  }
}

.ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li .ocw-text strong {
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-player-wrapper .ocw-movie-player-list li .ocw-text strong {
    font-size: 16px;
  }
}

.ocw-movie .ocw-movie-info-wrapper {
  background-image: url("../images/bg_pattern_stripe.png");
  background-repeat: repeat;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner {
    display: flex;
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info1 {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info1, .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 {
    width: calc((100% - 30px) / 2);
  }
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info1, .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 {
    width: 100%;
  }
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info1 {
  margin-right: 30px;
  font-size: 14px;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info1 .ocw-box {
  padding: 10px 15px 30px;
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info1 .ocw-box {
    padding-bottom: 20px;
  }
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info1 .ocw-movie-info1-data {
  padding: 10px 15px;
  background-color: #EEE;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info1 .ocw-movie-info1-data ul {
  display: block;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info1 .ocw-movie-info1-data ul::after {
  clear: both;
  content: "";
  display: block;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info1 .ocw-movie-info1-data li {
  float: left;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info1 .ocw-movie-info1-data li:not(:last-of-type) {
  margin-right: 1.5em;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info1 .ocw-cc {
  width: 88px;
  margin-top: 20px;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers {
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers {
    padding: 20px 25px;
  }
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers {
    padding: 15px 15px;
  }
}

@media screen and (min-width: 768px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -30px;
  }
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 30px * (2 - 1)) / 2) - 0.05px);
    max-width: calc(((100% - 30px * (2 - 1)) / 2) - 0.05px);
    margin-bottom: 30px;
  }
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers .ocw-items > ul > li:not(:nth-of-type(2n)) {
    margin-right: 30px;
  }
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers .ocw-items li {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers .ocw-items li:not(:last-of-type) {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers .ocw-items li .ocw-image {
    width: 33%;
  }
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers .ocw-items li .ocw-image {
    width: 80px;
  }
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers .ocw-items li .ocw-image img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers .ocw-items li .ocw-text {
  width: 67%;
  padding-left: 15px;
  line-height: 1.5;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers .ocw-items li .ocw-text .ocw-name {
  color: #4366B0;
  font-weight: bold;
  margin-bottom: 0;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-teachers .ocw-items li .ocw-text .ocw-ruby {
  font-size: 12px;
  margin-bottom: 2px;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link {
  padding: 10px 15px;
  font-size: 14px;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link-pdf:before, .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link-img:before, .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link-ext:before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  font-weight: bold;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link-pdf:before {
  content: '\f1c1';
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link-img:before {
  content: '\f03e';
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link-ext:before {
  content: '\f0c1';
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link ul {
  display: block;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link ul::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (min-width: 768px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link li {
    float: left;
  }
}

@media screen and (min-width: 768px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link li:not(:last-of-type) {
    margin-right: 1em;
  }
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link li:not(:last-of-type) {
    margin-bottom: 0.5em;
  }
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link a {
  color: #4366B0;
}

.ocw-movie .ocw-movie-info-wrapper .ocw-inner .ocw-movie-info2 .ocw-movie-info-link a span {
  text-decoration: underline;
}

.ocw-movie .ocw-movie-info-wrapper h4 {
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
  .ocw-movie .ocw-movie-r1-wrapper .ocw-items > ul, .ocw-movie .ocw-movie-r2-wrapper .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }
  .ocw-movie .ocw-movie-r1-wrapper .ocw-items > ul > li, .ocw-movie .ocw-movie-r2-wrapper .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 30px * (3 - 1)) / 3) - 0.05px);
    max-width: calc(((100% - 30px * (3 - 1)) / 3) - 0.05px);
    margin-bottom: 20px;
  }
  .ocw-movie .ocw-movie-r1-wrapper .ocw-items > ul > li:not(:nth-of-type(3n)), .ocw-movie .ocw-movie-r2-wrapper .ocw-items > ul > li:not(:nth-of-type(3n)) {
    margin-right: 30px;
  }
  .ocw-movie .ocw-movie-r1-wrapper .ocw-items > ul > li a, .ocw-movie .ocw-movie-r2-wrapper .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-movie .ocw-movie-r1-wrapper .ocw-items > ul > li a:hover, .ocw-movie .ocw-movie-r2-wrapper .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.ocw-movie .ocw-movie-r1-wrapper .ocw-items li, .ocw-movie .ocw-movie-r2-wrapper .ocw-items li {
  display: block;
  position: relative;
}

.ocw-movie .ocw-movie-r1-wrapper .ocw-items li::after, .ocw-movie .ocw-movie-r2-wrapper .ocw-items li::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-r1-wrapper .ocw-items li:not(:last-of-type), .ocw-movie .ocw-movie-r2-wrapper .ocw-items li:not(:last-of-type) {
    margin-bottom: 12px;
  }
}

.ocw-movie .ocw-movie-r1-wrapper .ocw-items li a, .ocw-movie .ocw-movie-r2-wrapper .ocw-items li a {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.ocw-movie .ocw-movie-r1-wrapper .ocw-items li a:hover, .ocw-movie .ocw-movie-r2-wrapper .ocw-items li a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.ocw-movie .ocw-movie-r1-wrapper .ocw-items .ocw-image, .ocw-movie .ocw-movie-r2-wrapper .ocw-items .ocw-image {
  float: left;
  width: 50%;
  padding-right: 12px;
}

.ocw-movie .ocw-movie-r1-wrapper .ocw-items .ocw-text, .ocw-movie .ocw-movie-r2-wrapper .ocw-items .ocw-text {
  float: left;
  width: 50%;
}

.ocw-movie .ocw-movie-r1-wrapper .ocw-items .ocw-text h5, .ocw-movie .ocw-movie-r2-wrapper .ocw-items .ocw-text h5 {
  color: #4366B0;
  font-size: 17px;
  border-bottom: none;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .ocw-movie .ocw-movie-r1-wrapper .ocw-items .ocw-text h5, .ocw-movie .ocw-movie-r2-wrapper .ocw-items .ocw-text h5 {
    font-size: 15px;
  }
}

.ocw-movie .ocw-movie-r1-wrapper .ocw-items .ocw-text h5:before, .ocw-movie .ocw-movie-r2-wrapper .ocw-items .ocw-text h5:before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  content: '\f008';
}

.ocw-movie .ocw-movie-r1-wrapper {
  border-width: 1px 0;
  border-style: solid;
  border-color: #B3C8E4;
  background-image: url("../images/bg_pattern_bluecheck.png");
  background-repeat: repeat;
}

@media screen and (min-width: 768px) {
  .ocw-movie .ocw-movie-r1-wrapper {
    padding: 25px 0;
  }
}

.ocw-movie .ocw-movie-r1-wrapper h4 {
  margin-bottom: 15px;
}

.ocw-movie .ocw-movie-r1-wrapper .ocw-movie-r1-box-title {
  border-radius: 5px 5px 0 0;
  border: 1px solid #6D97CF;
  padding: 2px 20px;
  background-color: #6D97CF;
  font-size: 18px;
  font-weight: bold;
  color: #FFF;
}

.ocw-movie .ocw-movie-r1-wrapper .ocw-movie-r1-box-title:before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  content: '\f07b';
}

.ocw-movie .ocw-movie-r1-wrapper .ocw-movie-r1-box-contents {
  padding: 20px;
  background-color: #FFF;
  border-radius: 0 0 5px 5px;
  border: 1px solid #6D97CF;
}

.ocw-movie .ocw-movie-r2-wrapper {
  background-image: url("../images/bg_pattern_stripe.png");
  background-repeat: repeat;
}

.ocw-movie .ocw-movie-r2-wrapper .ocw-movie-r2-box {
  padding: 20px;
  background-color: #FFF;
  border: 1px solid #DDD;
}

.ocw-movie .ocw-movie-r2-wrapper .ocw-movie-r2-box .ocw-category:before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  content: '\f07b';
  font-weight: bold;
}

@media screen and (min-width: 1000px) {
  .ocw-movie-index-course .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -30px;
  }
  .ocw-movie-index-course .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 30px * (3 - 1)) / 3) - 0.05px);
    max-width: calc(((100% - 30px * (3 - 1)) / 3) - 0.05px);
    margin-bottom: 30px;
  }
  .ocw-movie-index-course .ocw-items > ul > li:not(:nth-of-type(3n)) {
    margin-right: 30px;
  }
  .ocw-movie-index-course .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-movie-index-course .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .ocw-movie-index-course .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }
  .ocw-movie-index-course .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 20px * (3 - 1)) / 3) - 0.05px);
    max-width: calc(((100% - 20px * (3 - 1)) / 3) - 0.05px);
    margin-bottom: 20px;
  }
  .ocw-movie-index-course .ocw-items > ul > li:not(:nth-of-type(3n)) {
    margin-right: 20px;
  }
  .ocw-movie-index-course .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-movie-index-course .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media screen and (max-width: 767px) {
  .ocw-movie-index-course .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -15px;
  }
  .ocw-movie-index-course .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 0px * (1 - 1)) / 1) - 0.05px);
    max-width: calc(((100% - 0px * (1 - 1)) / 1) - 0.05px);
    margin-bottom: 15px;
  }
  .ocw-movie-index-course .ocw-items > ul > li:not(:nth-of-type(1n)) {
    margin-right: 0px;
  }
  .ocw-movie-index-course .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-movie-index-course .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.ocw-movie-index-course .ocw-items .ocw-item {
  position: relative;
  background-color: #FFF;
}

.ocw-movie-index-course .ocw-items .ocw-item .ocw-video img {
  width: 100%;
}

.ocw-movie-index-course .ocw-items .ocw-item .ocw-text {
  padding: 15px 10px;
}

@media screen and (max-width: 767px) {
  .ocw-movie-index-course .ocw-items .ocw-item .ocw-text {
    padding: 10px 10px;
  }
}

.ocw-movie-index-course .ocw-items .ocw-item .ocw-text .ocw-title {
  color: #4366B0;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .ocw-movie-index-course .ocw-items .ocw-item .ocw-text .ocw-title {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 2px;
  }
}

.ocw-movie-index-course .ocw-items .ocw-item .ocw-text .ocw-title:before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  content: '\f008';
  font-size: 1.3em;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .ocw-movie-index-course .ocw-contents-wrapper .ocw-box {
    padding: 15px;
  }
}

.ocw-movie-index-course .ocw-items-head h2:before {
  display: none;
}

.ocw-movie-index-course .ocw-items-head h2 small {
  display: block;
  font-size: 14px;
}

.ocw-movie-index-course .ocw-item h3 {
  border-left: none;
  padding-left: 0;
}

.ocw-movie-index section {
  padding: 25px 0;
}

@media screen and (min-width: 1000px) {
  .ocw-movie-index section .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -30px;
  }
  .ocw-movie-index section .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 30px * (3 - 1)) / 3) - 0.05px);
    max-width: calc(((100% - 30px * (3 - 1)) / 3) - 0.05px);
    margin-bottom: 30px;
  }
  .ocw-movie-index section .ocw-items > ul > li:not(:nth-of-type(3n)) {
    margin-right: 30px;
  }
  .ocw-movie-index section .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-movie-index section .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .ocw-movie-index section .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }
  .ocw-movie-index section .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 20px * (3 - 1)) / 3) - 0.05px);
    max-width: calc(((100% - 20px * (3 - 1)) / 3) - 0.05px);
    margin-bottom: 20px;
  }
  .ocw-movie-index section .ocw-items > ul > li:not(:nth-of-type(3n)) {
    margin-right: 20px;
  }
  .ocw-movie-index section .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-movie-index section .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media screen and (max-width: 767px) {
  .ocw-movie-index section .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -15px;
  }
  .ocw-movie-index section .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 0px * (1 - 1)) / 1) - 0.05px);
    max-width: calc(((100% - 0px * (1 - 1)) / 1) - 0.05px);
    margin-bottom: 15px;
  }
  .ocw-movie-index section .ocw-items > ul > li:not(:nth-of-type(1n)) {
    margin-right: 0px;
  }
  .ocw-movie-index section .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-movie-index section .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.ocw-movie-index section .ocw-items .ocw-item {
  position: relative;
  background-color: #FFF;
}

.ocw-movie-index section .ocw-items .ocw-item .ocw-video img {
  width: 100%;
}

.ocw-movie-index section .ocw-items .ocw-item .ocw-text {
  padding: 15px 10px;
}

@media screen and (max-width: 767px) {
  .ocw-movie-index section .ocw-items .ocw-item .ocw-text {
    padding: 10px 10px;
  }
}

.ocw-movie-index section .ocw-items .ocw-item .ocw-text .ocw-title {
  color: #4366B0;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .ocw-movie-index section .ocw-items .ocw-item .ocw-text .ocw-title {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 2px;
  }
}

.ocw-movie-index section .ocw-items .ocw-item .ocw-text .ocw-title:before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  content: '\f008';
  font-size: 1.3em;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .ocw-movie-index section {
    padding: 15px 0;
  }
}

.ocw-movie-index section:nth-of-type(2n+1) {
  background-image: url("../images/bg_pattern_stripe.png");
  background-repeat: repeat;
}

.ocw-movie-index section:nth-of-type(2n) {
  border-width: 1px 0;
  border-style: solid;
  border-color: #B3C8E4;
  background-image: url("../images/bg_pattern_bluecheck.png");
  background-repeat: repeat;
}

.ocw-movie-index section .ocw-items-head {
  display: block;
}

.ocw-movie-index section .ocw-items-head::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (min-width: 768px) {
  .ocw-movie-index section .ocw-items-head {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .ocw-movie-index section .ocw-items-head {
    margin-bottom: 12px;
  }
}

.ocw-movie-index section .ocw-items-head h2 {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  font-size: 21px;
  font-weight: bold;
  line-height: 1.4;
  color: #4366B0;
}

@media screen and (min-width: 768px) {
  .ocw-movie-index section .ocw-items-head h2 {
    float: left;
  }
}

.ocw-movie-index section .ocw-items-head h2:before {
  content: '\f07b';
  margin-right: 0.2em;
}

.ocw-movie-index section .ocw-items-head h2 small {
  font-size: 14px;
}

.ocw-movie-index section .ocw-items-head .ocw-items-head-more {
  display: table;
  font-size: 12px;
  padding: 4px 8px;
  line-height: 1;
  border-radius: 3px;
  background-color: #4366B0;
  color: #FFF;
}

@media screen and (min-width: 768px) {
  .ocw-movie-index section .ocw-items-head .ocw-items-head-more {
    float: right;
    margin-top: 4px;
  }
}

@media screen and (max-width: 767px) {
  .ocw-movie-index section .ocw-items-head .ocw-items-head-more {
    margin-top: 8px;
  }
}

.ocw-movie-index section .ocw-items-head .ocw-items-head-more:hover {
  background-color: #6D97CF;
}

.ocw-movie-index section .ocw-items-head .ocw-items-head-more:after {
  display: inline-block;
  vertical-align: middle;
  content: '';
  width: 0.6em;
  height: 0.6em;
  border-width: 3px 3px 0 0;
  border-style: solid;
  border-color: #FFF;
  margin-left: 0.2em;
  transform: translateY(-0.1em) rotate(45deg);
}

@media screen and (min-width: 1000px) {
  .ocw-search-result .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -30px;
  }
  .ocw-search-result .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 30px * (3 - 1)) / 3) - 0.05px);
    max-width: calc(((100% - 30px * (3 - 1)) / 3) - 0.05px);
    margin-bottom: 30px;
  }
  .ocw-search-result .ocw-items > ul > li:not(:nth-of-type(3n)) {
    margin-right: 30px;
  }
  .ocw-search-result .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-search-result .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .ocw-search-result .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }
  .ocw-search-result .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 20px * (3 - 1)) / 3) - 0.05px);
    max-width: calc(((100% - 20px * (3 - 1)) / 3) - 0.05px);
    margin-bottom: 20px;
  }
  .ocw-search-result .ocw-items > ul > li:not(:nth-of-type(3n)) {
    margin-right: 20px;
  }
  .ocw-search-result .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-search-result .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media screen and (max-width: 767px) {
  .ocw-search-result .ocw-items > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -15px;
  }
  .ocw-search-result .ocw-items > ul > li {
    position: relative;
    align-self: stretch;
    width: calc(((100% - 0px * (1 - 1)) / 1) - 0.05px);
    max-width: calc(((100% - 0px * (1 - 1)) / 1) - 0.05px);
    margin-bottom: 15px;
  }
  .ocw-search-result .ocw-items > ul > li:not(:nth-of-type(1n)) {
    margin-right: 0px;
  }
  .ocw-search-result .ocw-items > ul > li a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
  }
  .ocw-search-result .ocw-items > ul > li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.ocw-search-result .ocw-items .ocw-item {
  position: relative;
  background-color: #FFF;
}

.ocw-search-result .ocw-items .ocw-item .ocw-video img {
  width: 100%;
}

.ocw-search-result .ocw-items .ocw-item .ocw-text {
  padding: 15px 10px;
}

@media screen and (max-width: 767px) {
  .ocw-search-result .ocw-items .ocw-item .ocw-text {
    padding: 10px 10px;
  }
}

.ocw-search-result .ocw-items .ocw-item .ocw-text .ocw-title {
  color: #4366B0;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .ocw-search-result .ocw-items .ocw-item .ocw-text .ocw-title {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 2px;
  }
}

.ocw-search-result .ocw-items .ocw-item .ocw-text .ocw-title:before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
  content: '\f008';
  font-size: 1.3em;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .ocw-search-result .ocw-items-head h2 {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.ocw-search-result .ocw-items-head h2:before {
  display: none;
}

.ocw-search-result .ocw-items-head h2 small {
  display: block;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .ocw-search-result .ocw-items-head h2 small {
    font-size: 12px;
  }
}

.ocw-search-result .ocw-item h3 {
  border-left: none;
  padding-left: 0;
}

.ocw-article-index .ocw-article-entry {
  display: block;
  position: relative;
  padding: 30px;
  background-color: #FFF;
  border: 1px solid #DDD;
}

.ocw-article-index .ocw-article-entry::after {
  clear: both;
  content: "";
  display: block;
}

.ocw-article-index .ocw-article-entry a {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: transparent;
}

.ocw-article-index .ocw-article-entry a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 767px) {
  .ocw-article-index .ocw-article-entry {
    padding: 15px;
  }
}

.ocw-article-index .ocw-article-entry:not(:last-of-type) {
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .ocw-article-index .ocw-article-entry:not(:last-of-type) {
    margin-bottom: 15px;
  }
}

.ocw-article-index .ocw-article-entry h3 {
  margin-bottom: 5px;
}

@media screen and (min-width: 768px) {
  .ocw-article-index .ocw-article-entry h3 {
    float: left;
    width: 69.36937%;
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) {
  .ocw-article-index .ocw-article-entry h3 {
    width: 100%;
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 768px) {
  .ocw-article-index .ocw-article-entry .ocw-image {
    float: right;
    width: 30.63063%;
  }
}

@media screen and (max-width: 767px) {
  .ocw-article-index .ocw-article-entry .ocw-image {
    width: 100%;
    margin-bottom: 10px;
  }
}

.ocw-article-index .ocw-article-entry .ocw-image img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .ocw-article-index .ocw-article-entry .ocw-text {
    float: left;
    width: 69.36937%;
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) {
  .ocw-article-index .ocw-article-entry .ocw-text {
    width: 100%;
  }
}

.ocw-article-index .ocw-article-entry .ocw-text .ocw-meta {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .ocw-article-index .ocw-article-entry .ocw-text .ocw-meta {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 768px) {
  .ocw-article-index .ocw-article-entry-noimage .ocw-text {
    width: 100%;
  }
}

.ocw-article-mainvisual img {
  width: 100%;
}

.ocw-article .ocw-contents {
  display: block;
}

.ocw-article .ocw-contents::after {
  clear: both;
  content: "";
  display: block;
}

.ocw-article .ocw-contents h3 {
  margin-bottom: 5px;
}

.ocw-article .ocw-contents .ocw-meta {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .ocw-article .ocw-contents h5 {
    margin-bottom: 10px;
  }
}

.ocw-article .ocw-contents .ocw-outline {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .ocw-article .ocw-contents .ocw-outline {
    float: left;
    width: 69.36937%;
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) {
  .ocw-article .ocw-contents .ocw-outline {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .ocw-article .ocw-contents .ocw-image {
    float: right;
    width: 30.63063%;
  }
}

@media screen and (max-width: 767px) {
  .ocw-article .ocw-contents .ocw-image {
    width: 100%;
    margin-bottom: 20px;
  }
}

.ocw-article .ocw-contents .ocw-image img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .ocw-article .ocw-contents .ocw-detail {
    float: left;
    width: 69.36937%;
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) {
  .ocw-article .ocw-contents .ocw-detail {
    width: 100%;
  }
}

.ocw-article .ocw-contents .ocw-detail > .ocw-ol {
  font-weight: bold;
}

.ocw-article .ocw-contents .ocw-detail > .ocw-ol > li:not(:last-of-type) {
  margin-bottom: 5px;
}

.ocw-article .ocw-contents .ocw-detail > .ocw-ol .ocw-ul, .ocw-article .ocw-contents .ocw-detail > .ocw-ol p {
  font-weight: normal;
  margin-top: 5px;
}

.ocw-article .ocw-contents .ocw-detail > .ocw-ol .ocw-ul:not(:last-child), .ocw-article .ocw-contents .ocw-detail > .ocw-ol p:not(:last-child) {
  margin-bottom: 5px;
}
