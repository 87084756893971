@charset "UTF-8";

.ocw-home {
  overflow-x: hidden;
  .ocw-slides {
    position: relative;
    background-color: #FFF;
    padding-top: 30px;
    padding-bottom: 50px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    @include sp_tab {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 20px;
    }
    .ocw-inner {
      position: relative;
      @include sp_tab {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &:after {
      position: absolute;
      display: block;
      z-index: 1;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #FFF;
      opacity: 0.4;
    }
    .ocw-items {
      position: relative;
      z-index: 2;
      ul {
        .ocw-item {
          width: 100%;
          @include pc {
            display: table;
            table-layout: fixed;
          }
          @include sp_tab {
          }
          .ocw-video {
            position: relative;
            @include pc {
              display: table-cell;
              vertical-align: middle;
              width: percentage(770 / 1170);
              padding-top: percentage(770 / 1170 * 0.5625);
            }
            @include sp_tab {
              width: 100%;
              padding-top: 56.25%;
            }
            iframe {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              border: 0;
            }
          }
          .ocw-text {
            background-color: rgba(255,255,255,0.75);
            @include pc {
              display: table-cell;
              vertical-align: middle;
              width: percentage(400/1170);
              padding: 0 30px;
            }
            @include sp_tab {
              padding: 20px 20px 35px;
            }
            .ocw-title {
              @include text_shadow_w;
              font-size: 21px;
              font-weight: bold;
              line-height: 1.25;
              margin-bottom: 5px;
              a {
                color: $darkblue;
                &:hover {
                  color: $blue;
                }
              }
            }
            .ocw-meta {
              @include text_shadow_w;
              margin-bottom: 15px;
              color: $darkblue;
            }
            .ocw-content {
              color: #222;
              font-size: 16px;
              line-height: 1.6;
            }
          }
        }
      }
    }
    .swiper-pagination {
      left: 50%;
      line-height: 1;
      @include pc {
        top: 100%;
        bottom: auto;
        transform: translate(-50%, 15px);
      }
      @include sp_tab {
        top: auto;
        bottom: 12px;
        transform: translateX(-50%);
      }
      .swiper-pagination-bullet {
        @include notlast {
          margin-right: 9px;
        }
        width: 9px;
        height: 9px;
        background-color: #999;
        opacity: 1;
        &-active {
          background-color: #111;
        }
      }
    }
    .swiper-button-next, .swiper-button-prev {
      display: block;
      content: '';
      width: 30px;
      height: 30px;
      margin-top: -15px;
      background-image: none;
      border-style: solid;
      border-color: #FFF;
      transform: rotate(45deg);
    }
    .swiper-button-next {
      right: auto;
      left: 100%;
      border-width: 5px 5px 0 0;
    }
    .swiper-button-prev {
      right: 100%;
      left: auto;
      border-width: 0 0 5px 5px;
    }
  }
  .ocw-recent, .ocw-news {
    background-image: url('../images/bg_pattern_stripe.png');
    background-repeat: repeat;
  }
  .ocw-recommend {
    border-width: 1px 0;
    border-style: solid;
    border-color: #B3C8E4;
    background-image: url('../images/bg_pattern_bluecheck.png');
    background-repeat: repeat;
  }
  .ocw-recent, .ocw-recommend, .ocw-news {
    padding-top: 30px;
    padding-bottom: 30px;
    @include sp {
      padding-top: 0;
      padding-bottom: 20px;
    }
    h2 {
      position: relative;
      padding: 10px 20px 6px;
      margin-bottom: 20px;
      background-color: $darkblue;
      color: #FFF;
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
      @include sp {
        width: calc(100% + 30px);
        margin-left: -15px;
        padding: 8px 25px;
      }
      &:before {
        position: absolute;
        display: block;
        content: '';
        background-color: $blue;
        width: 5px;
        height: 17px;
        left: 9px;
        top: 50%;
        transform: translateY(-50%);
        @include sp {
          left: 15px;
        }
      }
    }
  }
  .ocw-recent, .ocw-recommend {
    @include video_grids;
  }
  .ocw-news {
    h2 {
      .ocw-more {
        position: absolute;
        right: 18px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        color: #FFF;
        &:after {
          display: inline-block;
          content: '';
          border-width: 3px 3px 0 0;
          border-style: solid;
          border-color: #FFF;
          width: 0.6em;
          height: 0.6em;
          transform: rotate(45deg);
          margin-left: 0.25em;
        }
      }
    }
    .ocw-items {
      @include pc {
        @include grids(2, 30px, 20px);
      }
      @include tab {
        @include grids(2, 15px, 15px);
      }
      @include sp {
        @include grids(1, 0, 15px);
      }
      .ocw-item {
        padding: 15px 20px;
        background-color: #FFF;
        border: 2px solid #DDD;
        .ocw-title {
          color: $blue;
          font-size: 17px;
          font-weight: bold;
          line-height: 1.4;
        }
        .ocw-content {
          font-size: 14px;
          line-height: 1.6;
        }
      }
    }
  }
  .ocw-banners {
    padding-top: 30px;
    padding-bottom: 30px;
    display: block;
    background-image: url('../images/img_link_bg.jpg');
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: cover;
    .ocw-items {
      @include pc {
        @include grids(6, 30px, 30px);
      }
      @include tab {
        @include grids(6, 15px, 15px);
      }
      @include sp {
        @include grids(3, 10px, 10px);
      }
      li {
        background-color: #FFF;
      }
      img {
        width: 100%;
      }
      a {
        position: static !important;
        display: block;
      }
    }
  }
}
