@charset "UTF-8";

html, body {
  margin: 0;
  padding: 0;
  font-family: "メイリオ", "Meiryo", "Hiragino Kaku Gothic Pro", sans-serif;
  color: #333;
  line-height: 1.85;
  font-size: 16px;
  @include sp {
    line-height: 1.6;
    font-size: 14px;
  }
}
body {
  @include sp {
    padding-top: 56px;
  }
  * {
    -webkit-tap-highlight-color: transparent;
    outline: none;
  }
}
li {
  list-style-type: none;
  list-style-position: inside;
}
a {
  text-decoration: none;
  &:hover {
    img {
      opacity: 0.8;
    }
  }
}
i {
  font-style: normal;
}
img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
select, button, input[type="submit"] {
  cursor: pointer;
}
.clearfix {
  @include row;
}
.ocw-inner {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @include sp_tab {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  @include pc {
    max-width: 1000px;
  }
  @include pc_l {
    max-width: 1170px;
  }
}
.ocw-meta {
  font-size: 14px;
  ul {
    display: table;
  }
  li {
    display: table-cell;
    @include notlast {
      padding-right: 1em;
    }
    &:before {
      @include fa;
      font-weight: bold;
      font-size: 0.9em;
    }
    &.ocw-date:before {
      content: "\f073";
    }
    &.ocw-byline:before {
      content: "\f406";
    }
  }
}
.ocw-main {
  .ocw-pagetitle {
    width: 100%;
    padding: 35px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    @include sp {
      padding: 25px 0;
    }
    &-sky {
      background-image: url('../images/header_sky.jpg');
    }
    &-green {
      background-image: url('../images/header_green.jpg');
    }
    h1 {
      font-size: 21px;
      font-weight: bold;
      color: #ffffff;
      line-height: 1.4;
      @include sp {
        font-size: 19px;
      }
      i {
        margin-right: 0.2em;
      }
    }
  }
  .ocw-contents-wrapper {
    padding: 30px 0;
    background-image: url('../images/bg_pattern_stripe.png');
    background-repeat: repeat;
    @include sp {
      padding: 20px 0;
    }
    .ocw-contents {
      padding: 30px;
      background-color: #FFF;
      @include sp {
        padding: 15px;
      }
    }
    h1, h2, h3, h4, h5, h6 {
      line-height: 1.4;
      br {
        @include sp_hide;
      }
    }
    h1 {
      padding: 12px 20px 8px;
      background-color: #E6E6E6;
      color: #333;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 35px;
      @include sp {
       padding: 10px 15px 6px;
        margin-bottom: 25px;
      }
      @include sp {
        font-size: 21px;
      }
      @include notfirstchild {
        margin-top: 35px;
      }
    }
    h2 {
      margin-bottom: 25px;
      color: $blue;
      font-size: 21px;
      font-weight: 700;
      @include sp {
        margin-bottom: 20px;
        font-size: 18px;
      }
      @include notfirstchild {
        margin-top: 35px;
        @include sp {
          margin-top: 25px;
        }
      }
      &:before {
        display: inline-block;
        vertical-align: middle;
        content: '';
        width: 0.9em;
        height: 0.9em;
        margin-right: 0.2em;
        margin-top: -0.15em;
        border-radius: 50%;
        background-color: $blue;
      }
    }
    h3 {
      position: relative;
      padding-left: 8px;
      margin-bottom: 25px;
      color: $blue;
      font-size: 21px;
      font-weight: 700;
      @include sp {
        margin-bottom: 20px;
        font-size: 18px;
      }
      border-left: 5px solid $blue;
      @include notfirstchild {
        margin-top: 35px;
        @include sp {
          margin-top: 25px;
        }
      }
    }
    h4 {
      padding: 10px 10px 8px 10px;
      margin-bottom: 25px;
      background-color: $darkblue;
      color: #FFF;
      font-size: 18px;
      font-weight: bold;
      @include sp {
        margin-bottom: 20px;
        margin-left: -15px;
        padding: 7px 15px 5px 15px;
        width: calc(100% + 30px);
      }
      @include notfirstchild {
        margin-top: 30px;
        @include sp {
          margin-top: 20px;
        }
      }
      &:before {
        display: inline-block;
        vertical-align: middle;
        content: '';
        width: 6px;
        height: 1em;
        margin-right: 0.4em;
        margin-top: -0.1em;
        background-color: $blue;
      }
    }
    h5 {
      margin-bottom: 25px;
      padding-bottom: 0.1em;
      border-bottom: 1px dotted $lightblue;
      font-size: 18px;
      font-weight: bold;
      color: $lightblue;
      @include sp {
        margin-bottom: 15px;
        font-size: 16px;
      }
      @include notfirstchild {
        margin-top: 30px;
        @include sp {
          margin-top: 15px;
        }
      }
    }
    h6 {
      margin-bottom: 25px;
      padding-bottom: 0.1em;
      border-bottom: 1px dotted #666;
      font-size: 18px;
      font-weight: bold;
      color: #666;
      @include sp {
        margin-bottom: 20px;
        font-size: 16px;
      }
      @include notfirstchild {
        margin-top: 30px;
        @include sp {
          margin-top: 20px;
        }
      }
    }
    p {
      @include notlastchild {
        margin-bottom: 20px;
      }
    }
    .ocw-ul, .ocw-ol {
      padding-left: 1.5rem;
      @include notlastchild {
        margin-bottom: 20px;
      }
      li {
        list-style-position: outside;
      }
      .ocw-ul, .ocw-ol, p {
        padding-left: 1.5rem;
      }
    }
    .ocw-ul {
      &-d > li {
        list-style-type: disc;
      }
      &-c > li {
        list-style-type: circle;
      }
      &-s > li {
        list-style-type: square;
      }
    }
    .ocw-ol {
      &-d > li {
        list-style-type: decimal;
      }
      &-la > li {
        list-style-type: lower-alpha;
      }
      &-lg > li {
        list-style-type: lower-greek;
      }
      &-lr > li {
        list-style-type: lower-roman;
      }
      &-ua > li {
        list-style-type: upper-alpha;
      }
      &-ur > li {
        list-style-type: upper-roman;
      }
    }
    .ocw-text {
      &-left {
        text-align-last: left;
      }
      &-center {
        text-align: center;
      }
      &-right {
        text-align: right;
      }
      &-justify {
        text-align: justify;
        text-justify: inter-ideograph;
        text-align-last: justify;
      }
    }
    .ocw-img {
      &-left {
        float: left;
        margin: 0 1em 1em 0;
      }
      &-center {
        margin: 0 auto;
      }
      &-right {
        float: right;
        margin: 0 0 1em 1em;
      }
    }
    .ocw-box {
      padding: 30px 40px;
      @include sp {
        padding: 25px 30px;
      }
      &-white {
        background-color: #FFF;
        border: 1px solid #DDD;
      }
      &-gray {
        background-color: #EEE;
      }
      &-red {
        background-color: #FFF0F0;
        border: 1px solid #E6ACB0;
      }
    }
    .ocw-table-wrapper {
      @include sp_tab {
        overflow-x: scroll;
        overflow-y: hidden;
      }
      table {
        width: 100%;
        min-width: 1000px;
        border-collapse: collapse;
        &, th, td {
          border-width: 1px;
          border-style: solid;
        }
        th, td {
          padding: 8px 20px;
          text-align: left;
        }
        thead {
          th {
            font-weight: bold;
            background-color: $blue;
            color: #FFF;
            border-color: $blue;
            @include notlast {
              border-right-color: #FFF;
            }
          }
        }
        tbody {
          td {
            border-color: #CCC;
          }
        }
      }
    }
    blockquote {
      padding: 40px 25px 30px;
      background-color: #F5F5F5;
      border-left: 5px solid #CCC;
      background-image: url('../images/blockquote.png');
      background-repeat: no-repeat;
      background-size: 34px 25px;
      background-position: 10px 10px;
    }
    strong {
      font-weight: bold;
      strong {
        font-weight: normal;
        text-decoration: underline;
      }
    }
    em {
      font-style: italic;
    }
    a {
      color: $blue;
      text-decoration: none;
      &:hover {
        color: $lightblue;
        &:before, &:after {
          opacity: 0.8;
        }
        img {
          opacity: 0.8;
        }
      }
    }
  }
}
a {
  &.ocw-link-blank, &.ocw-link-pdf,
  &.ocw-link-doc, &.ocw-link-xls, &.ocw-link-ppt {
    &:after {
      @include fa;
      margin-left: 0.5em;
      font-weight: bold;
      transform: translateY(-0.1em);
    }
  }
  &.ocw-link-blank:after {
    color: #888;
    content: '\f35d';
    font-size: 0.8em;
  }
  &.ocw-link-pdf:after {
    color: #D11B12;
    content: '\f1c1';
  }
  &.ocw-link-doc:after {
    color: #2C5898;
    content: '\f1c2';
  }
  &.ocw-link-xls:after  {
    color: #217346;
    content: '\f1c3';
  }
  &.ocw-link-ppt:after  {
    color: #D34C27;
    content: '\f1c4';
  }
}
.ocw-back-to-index, .ocw-index-more {
  padding: 25px 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: #B3C8E4;
  background-image: url('../images/bg_pattern_bluecheck.png');
  background-repeat: repeat;
  @include sp {
    padding: 15px 0;
  }
  a {
    display: block;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 570px;
    border-radius: 5px;
    line-height: 35px;
    background-color: $blue;
    color: #FFF;
    font-size: 14px;
    &:hover {
      background-color: $lightblue;
    }
  }
}
.ocw-back-to-index {
  a:before {
    display: inline-block;
    vertical-align: middle;
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-width: 0 0 3px 3px;
    border-style: solid;
    border-color: #FFF;
    margin-right: 0.2em;
    transform: translateY(-0.1em) rotate(45deg);
  }
}
.ocw-index-more {
  a:after {
    display: inline-block;
    vertical-align: middle;
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-width: 0 0 3px 3px;
    border-style: solid;
    border-color: #FFF;
    margin-left: 0.3em;
    transform: translateY(-0.2em) rotate(-45deg);
  }
}
.ocw-pagination {
  margin-top: 35px;
  padding-bottom: 5px;
  @include sp {
    margin-top: 20px;
    padding-bottom: 0;
  }
  ul {
    display: table;
    margin: 0 auto;
  }
  li {
    display: table-cell;
    padding: 0 2px;
  }
  a, span {
    display: block;
    text-align: center;
    width: 30px;
    line-height: 28px;
    border-width: 1px;
    border-style: solid;
    font-size: 12px;
    @include sp {
      width: 28px;
      line-height: 26px;
    }
  }
  a {
    background-color: #FFF;
    border-color: #CCC;
    color: #AAA !important;
  }
  &-current, a:hover {
    background-color: $blue;
    border-color: $lightblue;
    color: #FFF !important;
  }
  &-prev, &-next {
    &:after {
      display: inline-block;
      content: '';
      border-style: solid;
      border-width: 5px 6px;
      border-color: transparent;
    }
  }
  &-prev {
    &:after {
      border-right-color: #BBB;
      transform: translate(-25%, 12.5%);
    }
    &:hover {
      &:after {
        border-right-color: #FFF;
        opacity: 1 !important;
      }
    }
  }
  &-next {
    &:after {
      border-left-color: #BBB;
      transform: translate(25%, 12.5%);
    }
    &:hover {
      &:after {
        border-left-color: #FFF;
        opacity: 1 !important;
      }
    }
  }
}
