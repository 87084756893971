@charset "UTF-8";

/*
 * Typography
 */
@mixin fa {
  font-family: 'Font Awesome 5 Free';
  margin-right: 0.25em;
}
@mixin transition($time:0.07s) {
  &, &:before, &:after {
    transition: all $time linear;
  }
}

/*
 * Colors
 */
$blue: #4366B0;
$lightblue: #6D97CF;
$darkblue: #08225E;

/*
 * Buttons
 */

/*
 * Media Queries
 */
@mixin sp {
  @media screen and (max-width: 767px) {
    @content;
  }
}
@mixin sp_tab {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin tab {
  @media screen and (min-width: 768px) and (max-width: 999px) {
    @content;
  }
}
@mixin tab_pc {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin pc {
  @media screen and (min-width: 1000px) {
    @content;
  }
}
@mixin pc_l {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

/*
 * Mixins
 */
@mixin sp_show {
  @include tab_pc {
    display: none;
  }
}
@mixin sp_hide {
  @include sp {
    display: none;
  }
}
@mixin notfirst {
  &:not(:first-of-type) {
    @content;
  }
}
@mixin notlast {
  &:not(:last-of-type) {
    @content;
  }
}
@mixin notfirstchild {
  &:not(:first-child) {
    @content;
  }
}
@mixin notlastchild {
  &:not(:last-child) {
    @content;
  }
}
@mixin grids($cols, $gutter_h, $gutter_v) {
  & > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -#{$gutter_v};
    & > li {
      position: relative;
      align-self: stretch;
      // display: block;
      width: calc(((100% - #{$gutter_h} * (#{$cols} - 1)) / #{$cols}) - 0.05px);
      max-width: calc(((100% - #{$gutter_h} * (#{$cols} - 1)) / #{$cols}) - 0.05px);
      margin-bottom: $gutter_v;
      &:not(:nth-of-type(#{$cols}n)) {
        margin-right: $gutter_h;
      }
      a {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: transparent;
        &:hover {
          background-color: rgba(255,255,255,0.2);
        }
      }
    }
  }
}
@mixin video_grids {
  .ocw-items {
    @include pc {
      @include grids(3, 30px, 30px);
    }
    @include tab {
      @include grids(3, 20px, 20px);
    }
    @include sp {
      @include grids(1, 0px, 15px);
    }
    .ocw-item {
      position: relative;
      background-color: #FFF;
      .ocw-video {
        img {
          width: 100%;
        }
      }
      .ocw-text {
        padding: 15px 10px;
        @include sp {
          padding: 10px 10px;
        }
        .ocw-title {
          color: $blue;
          font-size: 17px;
          font-weight: bold;
          line-height: 1.4;
          margin-bottom: 5px;
          @include sp {
            font-size: 16px;
            line-height: 1.25;
            margin-bottom: 2px;
          }
          &:before {
            @include fa;
            content: '\f008';
            font-size: 1.3em;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
@mixin text_shadow_w {
  text-shadow: 0 0 1px #FFF, 0 0 1px #FFF,
    0 0 2px #FFF, 0 0 2px #FFF, 0 0 2px #FFF,
    0 0 3px #FFF, 0 0 3px #FFF, 0 0 3px #FFF,
    0 0 4px #FFF, 0 0 4px #FFF, 0 0 4px #FFF,
    0 0 5px #FFF, 0 0 5px #FFF, 0 0 5px #FFF;
}
.sp_show {
  @include sp_show;
}
.sp_hide {
  @include sp_hide;
}
